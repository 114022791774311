@import "../../../styles/variables";

.listItemImgInfo {
  min-width: 100px;
  max-width: 400px;
  display: table-cell;
  vertical-align: top;
  padding: 15px 0 0 15px;
  width: auto;
  max-height: 135px;

  & > :nth-child(2) {
    margin-top: 6px;
  }
}
