@import "../../../styles/variables";

.answer {
  border-radius: $defaultBorderRadius;
  border: 1px solid $light-7;
  width: 300px;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: #fff;

  &[data-drag-enter="true"] {
    border: 1px solid #000;
  }
}

.container {
  display: flex;
  align-items: center;
  margin-top: 30px;
  min-height: 60px;
  border: 2px dashed $light-7;
  border-radius: $defaultBorderRadius;
  width: fit-content;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;

  &[data-drag-enter="true"] {
    border: 2px dashed #000;
  }

  > *:last-child {
    margin-right: 0;
  }
}

.card {
  background-color: $light-10;
  border-radius: $defaultBorderRadius;
  text-align: center;
  width: 300px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: grab;
  user-select: none;

  > span {
    padding: 8px;
  }

  &[data-dragging="true"] {
    border-bottom: 4px solid rgba(20, 68, 139, 0.15);
  }

  &:active {
    border-bottom: 4px solid rgba(20, 68, 139, 0.15);
  }
}
