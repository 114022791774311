@import "../../../styles/variables";

.agenda {
  background-color: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: $defaultBorderRadius;
  padding: 28px 15px 15px 15px;
  min-width: 235px;
  max-width: 235px;
  margin-right: 77px;

  @media (max-width: 1400px) {
    margin-right: 50px;
  }
  & > span {
    display: flex;
    justify-content: center;
  }
}

.title {
  margin: auto;
  width: 90%;
  text-align: center;
  margin-bottom: 14px;
}

.searchBar {
  margin-bottom: 26px;
  & > div {
    border-radius: $defaultBorderRadius;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-top: 1px solid $light-6;
  }
}
