@import "../../../styles/variables";

.idpInfo {
  position: relative;
  width: 100%;
  height: 40vh;

  &__photo {
    position: absolute;
    top: 0;
    right: 0;
    width: 75%;
    height: 100%;
    background-image: var(--image-url);
    background-size: cover;
  }

  &__shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(91deg, $light-1 25%, transparent, $light-1);;
  }

  &__info {
    position: absolute;
    margin-left: 5vw;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3vh;
    align-items: flex-start;
  }
}
