@import "../../../styles/variables";
@import "../../../styles/base";

.userCompetences {
  @extend .base-layout;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    padding-bottom: 40px;
    width: 100%;
    max-width: 1376px;
  }
}

.ourCompetence {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  width: 1376px;
  max-width: 100%;
  flex-wrap: wrap;
  position: relative;
}

.addMore {
  background: $dark-7;
  padding-bottom: 70px;

  &__button {
    @extend .base-layout;

    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 30px 0;
  }
}

.addMoreRight {
  width: 100%;
  max-width: 1340px;
  margin-left: 10px;

  &[data-active="false"] {
    visibility: hidden;
  }
}

.competence {
  margin-top: 50px;
}

.stepButtons {
  @extend .base-layout;
  display: flex;
  justify-content: flex-end;
  padding: 40px 0;

  & > * {
    min-width: 137px;
  }
}
