@import "../../../styles/variables";

.wrapper {
  width: calc(100% + 2 * #{$tableHeaderPadding});
  margin: 0 -#{$tableHeaderPadding};
  display: grid;
  grid-gap: 1px;
  background-color: $light-1;

  path {
    stroke: $light-1;
  }
}

.header {
  background: $primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 36px;

  span {
    align-self: center;
    margin-left: 10px;
  }
}

.color {
  position: absolute;
  right: 0;
  min-width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 4px;
}

.arrowIcon {
  min-width: 12px;
  display: inline-block;
  margin-left: 5px;
}

.innerWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  svg {
    transition: 0.3s transform ease-in-out;
    opacity: 0;
  }
  &[data-is-sorted="true"] {
    svg {
      opacity: 1;
      transform: rotate(0);
    }

    &:hover {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &[data-is-sorted-desc="true"] {
    svg {
      transform: rotate(180deg);
    }

    &:hover {
      svg {
        transform: rotate(0);
      }
    }
  }

  &:hover {
    &[data-is-sortable="true"] {
      svg {
        opacity: 1;
      }
    }
  }
}
