@import "../../../styles/variables";
@import "../../../styles/base.scss";

.step {
  @extend .base-layout;
  display: flex;
  justify-content: center;

  &__form {
    width: 100%;
  }

  &__checkboxLink {
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    background: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    border-bottom: 1px solid $dark-1;
    padding: 0;
    margin-left: 5px;
  }
}

.stepsContainer {
  display: flex;
  justify-content: center;
}

.uploadImgWrapper {
  margin-right: 145px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadImg {
  position: relative;
  border-radius: $defaultBorderRadius;
  max-height: 574px;
  max-width: 574px;
  width: 30vw;
  height: 30vw;
  background: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    align-items: center;
    margin-top: 23px;

    input[type="file"] {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 200px;
      z-index: 1;
    }

    input[type="file"]::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      margin-right: 10px;
    }
  }
  > img {
    width: 100%;
    max-width: 235px;
    max-height: 235px;
    object-fit: cover;
  }
}

.backgroundBlur {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(119, 116, 116, 0.53);
  top: 0;
  left: 0;
  z-index: 4;
}

.errorMsg {
  color: $red;
  margin-left: 20px;
  position: absolute;
  bottom: -18px;
  left: 0;
}
