@import "../../../styles/variables";
@import "../../../styles/main";

.textArea {
  @extend .scroll;
  background: $light;
  border: none;
  width: 100%;
  border-radius: $defaultBorderRadius;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: $dark-4;
  height: 95px;
  padding: 10px 0 0 20px;
  outline: none;
  transition: 0.2s background ease-in-out;
  resize: none;

  &__disabled {
    background-color: $light-3;
  }

  &__error {
    border: 1px $red solid;
  }
}
