@import "../../../styles/variables";

.wrapper {
  position: relative;
}

.course {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 400px;
  max-width: 400px;
  background: $light;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: $defaultBorderRadius;
  position: relative;
  opacity: 0;
  transition: 0.1s ease-in-out transform;

  &__visible {
    display: block;
    opacity: 1;
  }

  &__imgContainer {
    position: relative;

    &_timeBar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  .coursePhoto {
    position: relative;
    height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      object-fit: cover;
      width: 100%;
      max-height: 225px;
      border-radius: $defaultBorderRadius;
    }

    button {
      cursor: pointer;
      border: none;
      background-color: transparent;
    }
  }

  &__hover {
    z-index: 5;
    animation: opacity 0.1s ease-in-out;
    position: absolute;
    top: -20px;
    left: 0;
    transform: translateX(-25px);

    @extend .course;
    max-width: 450px;
    width: 450px;

    .coursePhoto {
      height: 253px;

      > img {
        max-height: 253px;
      }
    }
  }
}

.matchPercent {
  display: block;
  margin-bottom: 10px;
}

.activeWrapper {
  padding: 0 24px 20px 24px;
  animation: opacity 0.3s ease-in-out;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.info {
  padding: 10px 10px 0px 25px;
}

.infoElement {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoInstuctor {
  display: flex;
  align-items: center;

  & > :first-child {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  }
  & > img {
    border-radius: 50%;
  }
}

.infoTopic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.infoRow {
  display: flex;
  margin: 7px 0;
  align-items: center;
  justify-content: space-between;
}

.infoWithIcon {
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.smallSlideHover {
  transform: scale(1.11);
}
