@import "../../../styles/variables";

.tileItemImgInfo {
  display: flex;
  align-items: center;
  gap: 10px;

  &[data-fullrow] {
    grid-column: 1/3;
  }

  & > div:nth-child(2) {
    margin-right: 10px;
  }
}
