@import "../../../../styles/main";

.outcomesPopup {
  opacity: 1;
  position: absolute;
  background-color: $light;
  top: 30px;
  right: -40px;
  width: 500px;
  z-index: 2;
  padding: 12px 35px;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  border-radius: $defaultBorderRadius;

  @media (max-width: 1400px) {
    width: 300px;
  }

  @extend .noSelect;
}
