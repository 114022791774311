@import "../../../styles/variables";
@import "../../../styles/base.scss";

.wrapper {
  @extend .base-layout;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
}

.barWrapperLeft {
  display: flex;
  flex-direction: column;

  width: 37%;
}

.barWrapperRight {
  display: flex;
  flex-direction: column;

  width: 37%;
}

.table {
  width: 100%;
}

.moreWrapper {
  background-color: $light-1;
  width: 100%;
  margin-top: 40px;

  &__innerWrapper {
    @extend .base-layout;

    padding: 40px 0 55px;
  }
}

.iconWrapper {
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  margin-bottom: 50px;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 64px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  float: right;
  margin-top: 31px;
}

//Calendar

.label {
  background-color: $light;
  max-width: max-content;
  font-size: 10px;
  line-height: 12px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  margin: -13px 0 -3px 20px;
  z-index: 1;
  padding: 1px 6px;
  font-weight: 600;
  color: $dark-8;
}

.datePickerWrapper {
  display: flex;
  flex-direction: column;
  :global {
    .DayPicker-NavBar {
      position: relative;

      .DayPicker-NavButton--prev {
        position: absolute;
        left: 85px;
        top: 3px;
        cursor: pointer;
        background-size: 4px;

        &::before {
          border: none;
          cursor: pointer;
          content: "";
          display: block;
          width: 17px;
          height: 1px;
          background-color: $dark-5;
          left: 12px;
          top: 9px;
          position: absolute;
        }
      }
      .DayPicker-NavButton--next {
        position: absolute;
        right: 85px;
        top: 3px;
        cursor: pointer;
        background-size: 4px;

        &::before {
          visibility: visible;
          border: none;
          cursor: pointer;
          content: "";
          display: block;
          width: 17px;
          height: 1px;
          background-color: $dark-5;
          right: 12px;
          top: 9px;
          position: absolute;
        }
      }
    }
    .DayPicker-Months {
      font-size: 8px;
      width: 330px;
      .DayPicker-Month {
        border-spacing: 30px 3px;
        border-collapse: separate;
      }
      .DayPicker-Caption {
        text-align: center;
      }
      .DayPicker-Weekdays {
      }
      .DayPicker-Body {
        .DayPicker-Week {
          .DayPicker-Day {
            border-radius: 3px;
            padding: 0.4em;
          }
          .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            background-color: $primary;
            font-weight: 800;
          }
        }
      }
    }
  }
}

.dateRanges {
  display: flex;
}

.dateRanges > div:last-child {
  margin-left: 7px;
}

.inputDatePicker {
  // @extend .datePickerWrapper;

  position: absolute;
  z-index: 4;
  :global {
    .react-datepicker {
      font-family: Lato;
      width: 331px;
      padding: 8px 19px 10px 19px;
      box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
      border: none;

      &__header {
        padding: 0;
        background-color: #fff;
        border-bottom: none;
        margin-bottom: 5px;
      }

      &__month {
        margin: 0;
      }

      &__current-month {
        font-size: 10px;
        color: $dark-4;
        font-weight: normal;
      }

      &__month-container {
        width: 100%;
        height: 100%;
      }

      &__day-names {
        display: flex;
        justify-content: space-between;
        margin: 13px 0 0 0;
      }

      &__day-name {
        padding: 1px 3px;
        color: $dark-5;
        flex: 1;
        font-size: 8px;
        margin: 0;
        text-align: left;
        line-height: normal;
      }

      &__week {
        display: flex;
        margin-bottom: 5px;
      }

      &__navigation--next {
        top: 9px;
        right: 100px;
        height: 10px;
        width: 20px;
      }

      &__navigation--previous {
        top: 9px;
        left: 100px;
        height: 10px;
        width: 20px;
      }

      &__navigation-icon--next {
        margin: auto;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 3px;
        height: 3px;
        border-top: 0.7px solid $dark-5;
        border-left: 0.7px solid $dark-5;
        transform: rotate(135deg);

        &::before {
          border: none;
          cursor: pointer;
          content: "";
          display: block;
          width: 0.5px;
          height: 17px;
          background-color: $dark-5;
          transform: rotate(-45deg) translate(3.7px, 0);
          left: 3px;
          top: 0px;
          position: absolute;
        }
      }

      &__navigation-icon--previous {
        margin: auto;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 3px;
        height: 3px;
        border-top: 0.7px solid $dark-5;
        border-left: 0.7px solid $dark-5;
        transform: rotate(-45deg);

        &::before {
          border: none;
          cursor: pointer;
          content: "";
          display: block;
          width: 0.5px;
          height: 17px;
          background-color: $dark-5;
          transform: rotate(-45deg) translate(3.7px, 0);
          left: 3px;
          top: 0px;
          position: absolute;
        }
      }

      &__day {
        padding: 1px 3px;
        flex: 1;
        color: $dark-4;
        width: 1rem;
        line-height: normal;
        font-size: 8px;
        margin: 0;
        text-align: left;
        border-radius: 0;
        background-color: #fff;

        &--outside-month {
          color: $dark-5;
        }

        &:hover {
          border-radius: 0;
          background-color: $range;
        }

        &--in-range {
          background-color: $range;
        }

        &--range-end {
          color: #fff;
          background-color: $ends;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;

          &:hover {
            background-color: $ends;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
        }

        &--selected {
          background-color: $ends;
          border-radius: 3px;
        }

        &--range-start {
          color: #fff;
          background-color: $ends;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;

          &:hover {
            background-color: $ends;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
        }

        &--in-selecting-range {
          border-radius: 0;
          background-color: $range !important;
        }
      }
    }
  }
  :global {
    .react-datepicker {
      width: 170px;
      top: 30px;
      left: 100%;
      &__navigation--next {
        top: 9px;
        right: 10px;
        height: 10px;
        width: 20px;
      }

      &__navigation--previous {
        top: 9px;
        left: 10px;
        height: 10px;
        width: 20px;
      }
    }
  }
}

.inputDatePickerEnd {
  @extend .inputDatePicker;

  :global {
    .react-datepicker {
      top: 205px;
      left: 100%;
    }
  }
}

:global {
  .DayPicker-wrapper {
    background-color: $light;
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
    border-radius: 5px;
  }
  .DayPicker-Caption {
    font-size: 10px;
    color: $dark-4;
  }
}
