@import "../../../styles/variables";

.timeBar__wrapper {
  display: flex;

  &[data-in-tile="true"] {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

.timeBar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  background: $light;
  box-shadow: 0px 0px 1px 0px $dark-2;
  border-radius: 0 5px 5px 0;
  overflow: hidden;
  width: calc(100% - 180px);
  margin-right: 8px;
  height: 15px;

  &[data-in-tile="true"] {
    margin: 10px 0 0 0;
    width: 100%;
  }

  &__background {
    position: absolute;
    height: 100%;
    border-radius: 0 5px 5px 0;
    left: 0;
    top: 0;
    background: $primary;
    width: 100%;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > :first-child {
      margin-right: 8px;
    }
  }
}

.textContainer {
  display: flex;
  justify-content: center;
  height: 13px;
}
