@import "../../../styles/main";
@import "../../../styles/scroll.scss";

.wrapper {
  position: relative;
  width: 100%;
  min-height: 130px;

  @extend .scroll;
}

.arrow {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  position: absolute;
  width: 11px;
  height: 11px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  border-radius: 1px;
  transform: rotate(135deg);
}

.items {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 40px;

  &[data-isgrid="true"] {
    display: flex;
    flex-wrap: wrap;
    gap: 48px 0;
  }

  &[data-isgrid="false"] {
    margin-top: -40px;
  }

  &[data-nomargin="true"] {
    border-spacing: 0;
  }

  &[data-fixed-layout="true"] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    flex-wrap: wrap;
    gap: 48px 20px;
    margin-bottom: 60px;
  }
}

.loaderWrapper {
  display: table-row;
  position: relative;
  height: 208px;
  width: 100%;
}

.pagination {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &[data-nomargin="true"] {
    margin: 0;
  }
}

.select {
  position: relative;

  select {
    cursor: pointer;
    width: 68px;
    padding: 6px 12px;
    outline: none;
    border-radius: $defaultBorderRadius;
    background: #fdfdfd;
    border: 1px solid #e5e5e5;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
  }

  .selectArrow {
    @extend .arrow;

    transform: rotate(-135deg);
    width: 8px;
    height: 8px;
    right: 15px;
    left: auto;
    margin: auto 0;
    pointer-events: none;
  }
}

.resultsString {
  display: flex;
  width: 115px;
  justify-content: center;
  align-items: center;
  margin: 0 32px 0 25px;
}

:global {
  .reactPagination {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;

    > * {
      cursor: pointer;
    }
  }

  .disabled {
    opacity: 0;
  }

  .page-link {
    &__active {
      background-color: #afdad7;
      border: none !important;
      color: #fff;
    }
  }

  .page-item {
    position: relative;
    color: $primary;
    border-radius: 50%;
    min-width: 24px;
    height: 24px;
    margin: 0 3.5px;
    background-color: transparent;
    user-select: none;
    :focus {
      outline: transparent;
    }

    :hover {
      color: $light;
      background-color: $primary;
    }

    > a {
      transition: 0.2s all ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid $primary;
      font-size: 12px;
      font-weight: 500;
      // line-height: 20; // Probably should be removed as it overflows last element on FilteredList (when selectable is on)
    }
  }

  .breakLink {
    background-repeat: no-repeat;
  }

  .previousButton {
    background-image: url(../../../assets/icons/paginationRightArrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(-180deg) translateY(1px);
    width: 11px;

    margin-right: 20px;
    > a {
      opacity: 0;
    }
  }

  .nextButton {
    background-image: url(../../../assets/icons/paginationRightArrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 20px;
    width: 11px;

    > a {
      opacity: 0;
    }
  }

  .active {
    > a {
      font-weight: 800;
      color: $light !important;
      background-color: $primary !important;
    }
  }
}

.noDataInformation {
  display: table-row;
  text-align: center;
  width: 100%;
}

.gridWrapper {
  margin-bottom: 40px;
  display: grid;
  width: 100%;
  gap: 48px 0;

  &[data-grid-size="4"] {
    grid-template-columns: repeat(3, minmax(312px, 1fr)) 312px;

    @media (max-width: 1730px) {
      grid-template-columns: repeat(2, minmax(312px, 1fr)) 312px;
    }

    @media (max-width: 1370px) {
      grid-template-columns: repeat(1, minmax(312px, 1fr)) 312px;
    }

    @media (max-width: 980px) {
      grid-template-columns: 312px;
    }
  }

  &[data-grid-size="3"] {
    grid-template-columns: repeat(2, minmax(400px, 1fr)) 400px;

    @media (max-width: 1690px) {
      grid-template-columns: repeat(1, minmax(400px, 1fr)) 400px;
    }

    @media (max-width: 1170px) {
      grid-template-columns: 400px;
    }
  }

  &[data-grid-size="2"] {
    grid-template-columns: repeat(1, minmax(700px, 1fr)) 700px;

    @media (max-width: 1870px) {
      grid-template-columns: 700px;
    }
  }
}
