@import "../../../../styles/variables";

.wrapper {
  padding-top: 40px;
  > span:first-child {
    display: block;
    padding-bottom: 9px;
  }
}

.pairRow {
  display: flex;
  align-items: center;
  padding-top: 11px;
}

.pairAnswer {
  border-radius: $defaultBorderRadius;
  border: 1px solid $light-7;
  background-color: $light-10;
  min-width: 140px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  &[data-dragging="true"] {
    border-bottom: 4px solid rgba(20, 68, 139, 0.15);
  }
}

.validOrderNumber {
  border: 1px solid $dark-4;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  color: $dark-4;
  max-width: 18px;
  max-height: 18px;
  width: 100%;
  height: 100%;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
  user-select: none;
}

.validOrderRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 17px;
  margin-bottom: 10px;
}
