@import "../../../../styles/variables";

.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.singleLineMultiInputs {
  display: flex;
  gap: 14px;
}

.multiInputsColumn {
  div.singleLineMultiInputs {
    flex-direction: column;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px $light-9 solid;

    & > div:first-child {
      margin-bottom: 10px;
    }
  }
}
