@import "../../../styles/base";
@import "../../../styles/scroll";

.background {
  background-color: $light-2;
}

.wrapper {
  @extend .base-layout;
  display: flex;
  padding: 40px 0 60px 0;
}

.tilesWrapper {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-left: 10px;
  @extend .scroll;
}

.userPanel {
  padding: 0 0 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > :nth-child(2) {
    margin: 0 25px 0 25px;
  }

  & > :nth-child(4) {
    margin: 0 20px;
  }
}

.header {
  min-width: 137px;
}

.loaderWrapper {
  position: relative;
}
