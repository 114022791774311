@import "../../../styles/main";

.dropDownNavWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.menuButton {
  width: 114px;
}

.dropDownMenu {
  z-index: 10;
  transition: all 0.4s;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 40px;
  display: flex;
  width: 338px;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  border-radius: $defaultBorderRadius;
  background: $light;

  &__left {
    width: 60%;
    padding: 16px 20px;
    flex-shrink: 2;
  }

  &__right {
    background: $light-2;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 20px;
    border-radius: 0 7px 7px 0;
  }

  ul {
    margin: 0;
    width: 100%;
    padding: 0;
    display: flex;
  }

  li {
    list-style: none;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.name {
  margin: 0 0 15px 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  &[data-registration="true"] {
    margin: 0;
  }

  &[data-profile="true"] {
    margin-left: 0px;
  }
}

.iconButton {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.userButton {
  @extend .iconButton;
  &:hover svg {
    fill: $primary;
  }
}

.shown {
  visibility: visible;
  opacity: 1;
}

.menu_links {
  > * {
    margin-bottom: 15px;
  }
}

.link {
  color: $dark;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;

  & > :first-child {
    margin-right: 4px;
  }

  & svg {
    transition: all 0.2s;
    fill: $dark;
  }

  &:hover {
    color: $primary;

    svg {
      fill: $primary;
    }
  }
}
