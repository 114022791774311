@import "../../../styles/variables";

.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 55px;
  max-width: 268px;

  &__item {
    width: 33px;
    height: 33px;
    border: 1px solid $primary;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $dark-1;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;

    &[data-active="true"] {
      color: $light;
      background: $primary;
      border: none;
    }

    &:nth-child(2) {
      position: relative;
      margin: 0 84px;
      &::before {
        content: "";
        left: 45px;
        position: absolute;
        height: 1px;
        width: 60px;
        background: $dark-1;
      }

      &::after {
        content: "";
        right: 45px;
        position: absolute;
        height: 1px;
        width: 60px;
        background: $dark-1;
      }
    }
  }
}
