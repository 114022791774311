@import "../../../styles/main";

.btn {
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 12px;
  line-height: 14px;
  border-radius: 5px;
}

.btnPrimary {
  @extend .btn;
  background: $primary;
  color: $light;
  padding: 6px 23px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $primary;
  transition: 0.3s background-color ease-in-out, 0.3s color ease-in-out;

  img {
    margin: 0 5px;
  }

  svg {
    margin: 0 7px;
    fill: $light;
    transition: 0.3s fill ease-in-out;
  }

  &:hover {
    background: transparent;
    color: $primary;

    svg {
      fill: $primary;
    }
  }
}

.btnPrimaryW100 {
  @extend .btnPrimary;
  width: 100%;
  padding: 6px 0;
}

.btnPrimaryH40 {
  @extend .btnPrimary;
  font-size: x-large;
  height: 40px;
  padding: 1em 0.7em 1em 1.6em;

  svg {
    width: 0.9em;
    height: 0.9em;
    margin-left: 1em;
  }
}

.btnPrimaryLight {
  @extend .btn;
  padding: 6px 23px;
  height: 24px;
  background: transparent;
  color: $dark-2;
  border: 1px solid $dark-2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s border-color ease-in-out, 0.3s color ease-in-out;

  img {
    margin: 0 5px;
  }

  svg {
    transition: 0.3s fill ease-in-out;
  }

  &:hover {
    color: $primary;
    border-color: $primary;

    svg {
      fill: $primary;
    }
  }
}

.btnPrimaryLightH40 {
  @extend .btnPrimaryLight;
  font-size: x-large;
  height: 40px;
  padding: 1em 0.7em 1em 1.6em;

  svg {
    width: 0.9em;
    height: 0.9em;
    margin-left: 1em;
  }
}

.primaryDisabled {
  @extend .btn;

  position: relative;
  height: 24px;
  border: 1px solid $dark-5;
  color: $dark-5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  padding: 6px 0;
  img {
    padding: 0 5px;
  }

  svg {
    margin: 0 7px;
    fill: $dark-5;
  }
  div{
    position: absolute;
    z-index:2;
    border-radius: 5px;
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
    background-color: $light;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease;
    top: 80%;
    color: black;
    padding: 10px 5px;
    width: 200%;
  }
  &:hover{
    & > div{
      opacity: 1;
      visibility: visible;
    }
  }
}

.btnPrimaryLightW100 {
  @extend .btnPrimaryLight;
  width: 100%;
}

.btnPrimaryLow {
  @extend .btnPrimary;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 20px;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
}

.btnPrimaryLowLight {
  @extend .btn;
  width: 150px;
  height: 20px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $dark-2;
  border: 1px solid $dark-2;
}

.btnText {
  @extend .btn;
  color: $primary;
  background: transparent;
  font-size: 10px;
  line-height: 12px;
}

.primaryInnerText {
  @extend .btn;
  background: transparent;
  color: $primary;
}

.navBarButton {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: $light;
  background: $primary;
  border: none;
  outline: none;
  padding: 7px 11px;
  border-radius: $defaultBorderRadius;
  cursor: pointer;
  align-items: center;
  height: 32px;

  img {
    transition: all 0.4s;
    &[data-isopen="true"] {
      transform: rotatex(180deg);
    }
  }
}

.crossButton {
  border: none;
  background-color: transparent;
  padding: 4px;
  outline: none;
  cursor: pointer;
}

.iconButton,
.iconButtonOn,
.iconButtonOff {
  position: relative;
  outline: none;
  padding: 0;
  cursor: pointer;
  border: 1px solid $primary;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  transition: 0.3s background-color ease-in-out;

  svg {
    transition: 0.3s fill ease-in-out;
    z-index: 1;
  }

  span {
    transition: all 0.3s;
  }

  &[disabled] {
    cursor: default;
  }
}

.iconButton:hover {
  background-color: $primary;

  span {
    transition: all 0.3s;
    color: #fff;
  }

  svg {
    fill: $light;
  }
}

.iconButton,
.iconButtonOff {
  background-color: transparent;

  svg {
    fill: $primary;
    display: block;
  }

  span {
    color: $primary;
  }
}

.iconButtonOn {
  background-color: $primary;

  svg {
    fill: $light;
  }

  span {
    color: transparent;
  }
}

.strokeIcon {
  outline: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  border: 1px solid $primary;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  transition: 0.3s background-color ease-in-out;

  svg {
    transition: 0.3s fill ease-in-out;
    stroke: $primary;
  }

  &:hover {
    background-color: $primary;

    svg {
      stroke: $light;
    }
  }
}

.warningBtn {
  @extend .btn;
  padding: 6px 14px;
  height: 24px;
  background: transparent;
  color: $red;
  border: 1px solid $red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  display: block;
}

.tooltip {
  box-shadow: 0 8px 8px 1px rgba(20, 68, 139, 0.15);
  z-index: 9;
}

:global {
  .react-tooltip__show {
    opacity: 1.0 !important;
  }
}
