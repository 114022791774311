@import "../../../styles/variables";
@import "../../../styles/scroll.scss";

.tableWrapper {
  background: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 10px 15px 40px 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow-x: auto;

  &__transparent {
    background: none;
    box-shadow: none;
  }

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 15px;
  }
}
