@import "../../../styles/main";

.switchMenu {
  @extend .base-layout;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 94px;
  background: $light-1;
  width: 100%;
}

.list {
  height: 45px;
  margin: 0;
  max-width: calc(100% - 80px);

  @media (max-width: 1400px) {
    max-width: calc(100% - 40px);
  }

  padding: 0;
  flex: 6;
  display: flex;
  list-style: none;
}

ul li:last-child a {
  margin: 0;
}

.link {
  display: block;
  height: 100%;
  padding: 0 15px;
  color: $dark-2;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  transition: 0.1s color ease-in-out;
  width: max-content;
  margin-right: 80px;

  @media (max-width: 1400px) {
    margin-right: 40px;
  }

  &:hover {
    color: $dark;
  }

  &__active {
    color: $dark;
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    border-bottom: 3px solid $primary;
  }
}
