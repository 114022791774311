@import "../../../styles/variables";

.slide {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: var(--image-url);
  background-size: cover;
  background-position: center;

  &__info {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 5%;
    width: 90%;
    min-height: 25%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 5vh;

    &__titleBox {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &__instructorsBox {
      display: flex;
      flex-direction: column;

      &__list {
        display: flex;
        flex-direction: row;
        gap: 40px;

        & > div {
          display: flex;
          flex-direction: row;
          gap: 20px;
          align-items: center;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 40px;
  }

  &__bottomShadow {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40%;
    z-index: 0;
    background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.85) 65%, $light-1);
  }
}
