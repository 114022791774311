@import "../../../styles/variables";

.button {
  margin-top: 5px;
  height: 32px;
  width: 32px;
  position: relative;

  &__border {
    border-radius: 18px;
    border: 1px solid $light;
    padding: 6px;
    width: 32px;
    height: 32px;
    font-size: 14px;

    svg {
      fill: $light;
      width: 18px;
      height: 18px;
    }

    &__marked {
      background-color: $light;

      svg {
        fill: $secondary;
      }
    }
  }
}

.hoverSeparator {
  height: 10px;
  width: 100%;
}

.hoverSubmenuWrapper {
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  border-radius: $defaultBorderRadius;
  background-color: $light;
  padding: 6px 12px 9px;
  gap: 8px;
  width: 130px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: 0px;
  font-size: 14px;

  &::after {
    content: "";
    position: absolute;
    height: 4px;
    width: 187px;
    top: -4px;
    left: 0;
  }

  span {
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }
}

.hoverDatePickerWrapper {
  max-width: 173px;

  & > :first-child {
    display: block;
  }

  :global {
    .react-datepicker {
      position: absolute;
      font-family: Lato;
      width: 173px;
      padding: 8px 19px 10px 19px;
      box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
      border: none;
      z-index: 5;

      &__header {
        padding: 0;
        background-color: #fff;
        border-bottom: none;
        margin-bottom: 5px;
      }

      &__month {
        margin: 0;
      }

      &__current-month {
        font-size: 10px;
        color: $dark-4;
        font-weight: normal;
      }

      &__month-container {
        width: 100%;
        height: 100%;
      }

      &__day-names {
        display: flex;
        justify-content: space-between;
        margin: 13px 0 0 0;
      }

      &__day-name {
        padding: 1px 3px;
        color: $dark-5;
        flex: 1;
        font-size: 8px;
        margin: 0;
        text-align: left;
        line-height: normal;
      }

      &__week {
        display: flex;
        margin-bottom: 5px;
      }

      &__navigation--next {
        top: 9px;
        right: 18px;
        height: 10px;
        width: 20px;
      }

      &__navigation--previous {
        top: 9px;
        left: 14px;
        height: 10px;
        width: 20px;
      }

      &__navigation-icon--next {
        margin: auto;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 3px;
        height: 3px;
        border-top: 0.7px solid $dark-5;
        border-left: 0.7px solid $dark-5;
        transform: rotate(135deg);

        &::before {
          border: none;
          cursor: pointer;
          content: "";
          display: block;
          width: 0.5px;
          height: 17px;
          background-color: $dark-5;
          transform: rotate(-45deg) translate(3.7px, 0);
          left: 3px;
          top: 0px;
          position: absolute;
        }
      }

      &__navigation-icon--previous {
        margin: auto;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 3px;
        height: 3px;
        border-top: 0.7px solid $dark-5;
        border-left: 0.7px solid $dark-5;
        transform: rotate(-45deg);

        &::before {
          border: none;
          cursor: pointer;
          content: "";
          display: block;
          width: 0.5px;
          height: 17px;
          background-color: $dark-5;
          transform: rotate(-45deg) translate(3.7px, 0);
          left: 3px;
          top: 0px;
          position: absolute;
        }
      }

      &__day {
        padding: 1px 3px;
        flex: 1;
        width: 1rem;
        line-height: normal;
        font-size: 8px;
        margin: 0;
        text-align: left;
        border-radius: 0;
        background-color: #fff;

        &--outside-month {
          color: $dark-2;
        }

        &:hover {
          border-radius: 0;
          background-color: $range;
        }

        &--range-start {
          background-color: $ends;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          color: $dark-4;

          &:hover {
            background-color: $ends;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
        }

        &--in-range {
          background-color: $range;
          color: $dark-4 !important;
        }

        &--range-end {
          background-color: $ends;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          color: $dark-4 !important;

          &:hover {
            background-color: $ends;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
        }

        &--selected {
          background-color: $ends;
          border-radius: 3px;
          color: $dark-4;
        }

        &--in-selecting-range {
          border-radius: 0;
          color: $dark-4;
          background-color: $range !important;
        }
      }
    }
  }
}
