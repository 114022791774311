@import "../../../styles/main";

.competenceTestReport {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  &__box {
    @extend .base-layout;

    width: 100%;
    position: relative;
    min-height: 10vh;
  }
}
