@import "../../../styles/variables";
.Wrapper {
  display: flex;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;
  width: 100%;
  &:hover {
    span {
      color: $primary;
    }
  }

  &__active {
    span {
      color: $primary;
    }
  }

  &[data-notification="true"] {
    margin-top: 0;
  }
}

.checkbox {
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  border-radius: 3px;
  border: 1px solid $dark-4;
  display: flex;
  justify-content: center;
  align-items: center;

  &__active {
    border: none;
  }
  &[data-notification="true"] {
    min-width: 18px;
    min-height: 18px;
    max-width: 18px;
    max-height: 18px;
    margin-right: 0;
  }
}

.wrapperInside {
  display: flex;
  gap: 15px;
  align-items: center;

  &[data-notification="true"] {
    justify-content: center;
    align-items: center;
  }

  > img {
    margin-left: 35px;
    max-width: 23px;
    max-height: 23px;
    border-radius: 50%;
  }
}
