.names {
  display: flex;
  flex-direction: column;
}

.images {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
