@import "../../../styles/variables";

.progressBar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 1px 8px;
  box-shadow: 0px 0px 1px 0px $dark-2;
  border-radius: 20px;
  width: 100%;
  min-width: 100px;
  height: 17px;

  span {
    display: block;
  }

  .background {
    position: absolute;
    height: 100%;
    border-radius: 20px;
    left: 0;
    top: 0;
    transition: 0.3s width ease-in-out;
    min-width: 30px;
  }
}

.progressBarTable {
  @extend .progressBar;

  height: 25px;
}

.progressBarTablePrimary {
  @extend .progressBarTable;

  background: $light;

  .background {
    background: $primary;
  }
}

.progressBarTableLight {
  @extend .progressBarTable;

  background: transparent;

  .background {
    background: #8fc2bf;
  }
}

.progressBarSquared {
  @extend .progressBar;

  height: 22px;
  border-radius: 5px;
  margin-top: 15px;
  background: $light;

  .background {
    border-radius: 5px;
    background: $primary;
  }
}

.progressBarTransparent {
  @extend .progressBar;

  width: 300px;
  height: 22px;
  border-radius: 20px;
  border: 1px solid $light;

  .background {
    color: $light;
    background-color: $primary;
    border-radius: 20px;
    margin: -1px;
    height: calc(100% + 2px);
  }
}

.informationSingUp {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.information {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip {
  box-shadow: 0 8px 8px 1px rgba(20, 68, 139, 0.15);
  display: flex;
  gap: 4px;
}
