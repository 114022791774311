@import "../../../../styles/base";

.signIn {
  background: $light-1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.formWrapper {
  max-width: 907px;
  width: 50%;
  position: relative;
  padding: 30px 30px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__logo {
    position: relative;
    width: 100%;

    img {
      width: 128px;
      height: 37px;
      cursor: pointer;
    }
  }

  &__box {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 482px;
    width: 100%;
  }

  &__title {
    text-align: center;
    margin-bottom: 36px;
  }

  &__description {
    margin-bottom: 36px;
  }

  &__parpLogos {
    width: 100%;
    height: 70px;

    & > a {
      margin: 0 auto;
      display: block;
      width: fit-content;

      & > img {
        height: 70px;
      }
    }
  }
}

.imgWrapper {
  width: 50%;
}
