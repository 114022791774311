@import "../../../styles/base";

.wrapper {
  @extend .base-layout;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.statistics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  @media (max-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
