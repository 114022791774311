@import "../../../styles/variables";

.wrapper {
  display: flex;
  align-items: center;

  & input[type="checkbox"] {
    display: none;
  }

  & input[type="checkbox"]:checked + .checkbox {
    background-image: url("../../../assets/icons/checkbox-checked.svg");
  }

  & input[type="checkbox"]:checked ~ .label {
    color: $primary;
  }
}

.checkbox {
  display: inline-block;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  cursor: pointer;
  border: 1px solid $dark-4;
  border-radius: 3px;
  margin-right: 17px;

  &__checked {
    border: none;
  }

  &__disabled {
    border: 1px solid $dark-8;
  }

  &__error {
    border: 1px solid $red;
  }
}
