@import "../../../styles/variables";

.Chart {
  position: relative;
  width: 140px;
  height: 116px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div:last-child {
    & > div > svg {
      overflow: visible;
    }
  }
}

.ChartBig {
  @extend .Chart;
  width: 190px;
  height: 190px;

  > .ChartBorderInside {
    width: 125px;
    height: 125px;

    > .ChartNumber {
      font-size: 36px;
    }
  }

  > .ChartBorderOutside {
    width: 190px;
    height: 190px;
  }
}

.ChartBorderInside {
  position: absolute;
  width: 83px;
  height: 83px;
  border: 3px solid $secondary;
  box-shadow: inset;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChartBorderOutside {
  @extend .ChartBorderInside;
  height: 116px;
  width: 116px;
}

.ChartBorderOutsideFilter {
  @extend .ChartBorderInside;
  height: 118px;
  width: 118px;
  border: none;
  outline: 15px solid rgba(255, 255, 255, 0.5);
  z-index: 2;
  pointer-events: none;

  &[data-test="true"] {
    height: 190px;
    width: 190px;
  }
}

.ChartNumber {
  color: $secondary;
  z-index: 2;
  font-weight: 800;
  font-size: 24px;
}

.statisticBox {
  display: flex;
  flex-direction: column;
  background-color: $light;
  text-align: center;
  padding: 8px;
  min-width: 140px;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  & > span:first-of-type{
    line-break: anywhere;
  }
}
