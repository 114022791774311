@import "../../../styles/variables";

.navigation {
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 10px;
}

.items {
  list-style: none;
  margin: 0;
  padding: 0;
  text-indent: 0;
  cursor: pointer;

  li:not(:last-child) {
    margin-bottom: 3px;
  }
}
