@import "../../../../styles/variables";
@import "../Table.module";
@import "../../../../styles/scroll.scss";

.table {
  width: 100%;
}

.tableHeader {
  padding-left: 13px !important;
  min-width: 140px;
  height: 36px;

  path {
    stroke: $light-1;
  }

  svg {
    margin-left: 5px;
  }
}

.action {
  text-align: center;
  margin-right: 15px;
}

.actionBox {
  display: flex;
  justify-content: center;
}

.wrapper {
  overflow-x: auto;
  @extend .scroll;
}
