@import "../../../styles/variables";

.headerWrapper {
  min-width: 100%;
  display: flex;
  column-gap: 1px;

  & > :first-child {
    min-width: 145px;
    background-color: $background-color;
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 11px;
  }
}

.rowsWrapper {
  border: 1px solid $light-6;
  background-color: $light-6;
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  column-gap: 1px;
}

.tableHeader {
  min-width: 100px;
  display: flex;
  background-color: $primary;
  justify-content: center;
  align-items: center;
  height: 36px;

  &__primary {
    background-color: $primary !important;
  }
}

.tableRow {
  @extend .tableHeader;

  background-color: $background-color;
}
