.courseSwiperWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow-y: visible;

  &__hovered {
    z-index: 2;
  }

  :global {
    .swiper-container {
      width: 100%;
      height: 100%;
    }
  }

  & > span:first-child {
    margin: 0 var(--h-margins);
  }
}

.courseSwiper {
  overflow-y: visible;
  overflow-x: clip;

  :global {
    .swiper-slide {
      width: auto;

      &:first-child {
        margin-left:  var(--h-margins);
      }

      &:last-child {
        margin-right: var(--h-margins) !important;
      }
    }
  }
}
