@import "../../../styles/variables";

.Chart {
  position: relative;
  width: 205px;
  height: 205px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $secondary;
  font-weight: 800;
  font-size: 24px;
  & > div:last-child {
    & > div > svg {
      overflow: visible;
    }
  }

  @media (max-width: 1620px) {
    max-width: 130px;
    max-height: 130px;
  }

  @media (max-width: 1400px) {
    max-width: 205px;
    max-height: 205px;
  }
}

.ChartInside {
  position: absolute;
}

.ChartBorderOutsideFilter {
  position: absolute;
  width: 205px;
  height: 205px;
  box-shadow: inset;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: 15px solid rgba(255, 255, 255, 0.5);
  z-index: 2;
  pointer-events: none;

  @media (max-width: 1620px) {
    max-width: 130px;
    max-height: 130px;
  }

  @media (max-width: 1400px) {
    max-width: 205px;
    max-height: 205px;
  }
}

.chartPlaceholder {
  width: 205px;
  height: 205px;
  border-radius: 50%;
  background-color: $light-2;
  border: dashed 2px $light-9;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1620px) {
    max-width: 130px;
    max-height: 130px;
  }

  @media (max-width: 1400px) {
    max-width: 205px;
    max-height: 205px;
  }

  &__fill {
    text-align: center;
    line-height: 138px;
    border: dashed 2px $light-9;
    display: block;
    background-color: $light;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    height: 138px;
    width: 138px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 1620px) {
      max-width: 90px;
      max-height: 90px;
    }

    @media (max-width: 1400px) {
      max-width: 138px;
      max-height: 138px;
    }
  }

  & > span {
    z-index: 2;
  }
}

.statisticBox {
  background-color: $light;
  text-align: center;
  padding: 8px 16px;
  min-width: 140px;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  display: flex;
  align-items: flex-start;
  gap: 10px;
  grid-gap: 10px;
  white-space: nowrap;
  & > div:first-child {
    height: 12px;
    width: 12px;
  }
  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
