@import "../../../styles/variables";
@import "../../../styles/scroll.scss";

.TextWrapper {
  position: relative;
  color: $dark-2;
  border-radius: $defaultBorderRadius;
  display: flex;
  flex-direction: column;

  &[data-secondary="true"] {
    color: $secondary;
  }

  &:hover {
    .truncatedText {
      visibility: visible;
    }
  }
}

.lineClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:hover {
    .truncated {
      position: absolute;
      overflow: auto;
      display: flex;
      flex-direction: column;
      background-color: $light;
      left: -10px;
      top: 0px;
      padding: 0 10px;
      z-index: 2;
      border-radius: 5px;
      box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
      white-space: nowrap;
      min-height: 100%;
      max-height: 240px;

      @extend .scroll;
    }
  }
}

.truncated {
  overflow: hidden;
  display: none;
}

.widthInformation {
  position: absolute;
  opacity: 0;
  padding: 0;
  left: -2000px;
}
