@import "../../../styles/variables";

.wrapper {
  cursor: pointer;
  color: $dark-4;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  gap: 15px;
}

.wrapperInTable {
  @extend .wrapper;
  cursor: unset;
}

.disabled {
  & span {
    color: $dark-8;
  }

  & img, & svg {
    opacity: 0.3;
  }
}
