.requirement {
  display: flex;
  flex-direction: row;
  gap: 10px;

  & > img {
    width: 15px;
    height: 15px;
  }
}
