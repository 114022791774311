@import "../../../styles/base.scss";

.stepsContainer {
  display: flex;
  justify-content: center;
}

.registration {
  @extend .base-layout;

  padding: 0 80px;
}
