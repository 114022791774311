.Wrapper {
  width: 100%;
  margin-bottom: 20px;
  & > :first-child {
    margin-right: 4px;
  }
  & > :last-child {
    margin-left: 1px;
  }
}
