@import "../../../styles/variables";

.mainContent {
  width: 350px;
  display: flex;
  gap: 20px;
}

.textContent {
  display: flex;
  flex-direction: column;
}

.rating {
  margin-top: 20px;
  display: flex;
  gap: 8px;
}
