@import "../../../../styles/variables";

.wrapper {
  display: flex;
  width: 100%;
  margin: auto;
  gap: 26px;
  position: relative;
}

.information {
  text-align: center;
}
