.starContainer {
  @keyframes animation {
    0% {
      transform: rotate(0deg);
    }

    30% {
      transform: rotate(10deg);
    }

    70% {
      transform: rotate(-10deg);
    }
  }
  &:hover {
    animation: animation 0.3s ease-in-out;
  }
}
