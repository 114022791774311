.Wrapper {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  width: 100%;

  & > :first-child {
    margin-right: 20px;
    width: 84px;
  }

  & > :last-child {
    min-width: 84px !important;
    max-width: 84px !important;
  }
}
