@import "../../../styles/main";

.container {
  @extend .base-layout;

  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10%;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 40%;
}

.mfa {
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 20px;
  }
}

.box {
  background: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 10px 15px 40px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
  flex: 1;
}

.privacyButtonsWrapper {
  display: flex;
  align-items: flex-end;

  & > * {
    flex: 1;
  }
}