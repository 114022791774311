@import "../../../styles/variables";

.Wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $light-3;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.TableLoader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $light-1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;

  &[data-color="light"] {
    background-color: $light;
  }

  &[data-color="light-1"] {
    background-color: rgba(249, 249, 249, 0.7);
  }

  &[data-color="transparent"] {
    background-color: transparent;
  }
}

.TableBodyLoader {
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 1px);
  border: 1px solid $light-6;
  border-top: none;
  padding: 10px 0;
}

.TableSubrowLoader {
  height: 100px;
}

.PopupLoader {
  @extend .TableLoader;

  background-color: rgba(170, 170, 170, 0.385);
}

.contextLader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(170, 170, 170, 0.385);
  z-index: 6;
}

.videoLoader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
}
