.previewPlayer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;

  &__player {
    padding-top: 2%;
    padding-bottom: 2%;
    width: 80% !important;
    margin: auto;

    &__visible {
      visibility: visible;
    }

    &__hidden {
      visibility: hidden;
    }
  }
}

