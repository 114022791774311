@import "../../../styles/variables";

.workgroup {
  position: relative;
  background: $light;
  min-width: 308px;
  max-width: 308px;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;

  &:hover {
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: calc(100% - 5px);
    height: 2px;
    background: $primary;
    z-index: 1;
    border-radius: 3px;
    transition: 0.3s opacity ease-in-out;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  &__box {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    margin-bottom: 30px;
    gap: 10px;
  }

  &__img {
    width: 116px;
    height: 94px;
    object-fit: cover;
  }

  &__description {
    margin-top: 5px;
    grid-column: 1/3;
    display: flex;
    flex-direction: column;
    span:first-child {
      margin-bottom: 6px;
    }
  }

  &__nameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__information {
    display: flex;
    flex-direction: column;
    color: $dark-2;
    margin-top: 5px;
    span:first-child {
      margin-bottom: 6px;
    }
  }
}

.workgroup__innerBox {
  display: flex;
  position: relative;
}

.visibilityImg {
  position: absolute;
  right: -5px;
  top: -10px;
  width: 35px;
  height: 35px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.buttonsContainer {
  display: flex;
  position: absolute;
  bottom: 15px;

  &[data-active="true"] {
    margin-top: 10px;
  }

  > :first-child {
    margin-right: 13px;
  }
}
