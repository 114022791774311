@import "../../../styles/variables";

.Wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  p {
    margin: 0;
  }
}

.LegendSquare {
  height: 24px;
  width: 24px;
  background-color: green;
  border-radius: 3px;
  margin: 5px 10px 5px 0;
}
