@import "../../../styles/variables";
@import "../../../styles/main.scss";

.footerLayout {
  background-color: $light-1;
  border-top: 6px solid $secondary;
  max-height: 362px;

  .footer {
    display: flex;
    justify-content: center;
    padding: 45px 0;
    @extend .base-layout;
  }

  .footer > div:not(:last-child) {
    margin-right: 85px;

    @include respond-to("mid-large") {
      margin-right: 45px;
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  & > img {
    width: 128px;
  }
}

.socialMedias {
  margin-top: 85px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
}
