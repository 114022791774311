@import "../../../../styles/variables";

.Wrapper {
  width: 100%;
  display: table-row;
  gap: 1px;
  background-color: $light-6;
  border-left: 1px solid $light-6;
  border-right: 1px solid $light-6;
  & > div {
    width: 16.66%;
    & > div {
      width: 20%;
    }
  }

  & > :first-child {
    width: 50%;
  }
}
