@import "../../../styles/main";

.notifications {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__tableContainer {
    @extend .base-layout;

    width: 100%;
  }
}
