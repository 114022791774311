@import "../../../styles/variables";

.instructorSwiperWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;

  :global {
    .swiper-container {
      width: 100%;
      height: 100%;
    }
  }

  & > span:first-child {
    margin: 0 5vw;
  }
}

.instructorSwiper {
  :global {
    .swiper-slide {
      width: 375px;
      height: 500px;

      &:first-child {
        margin-left: 5vw;
      }

      &:last-child {
        margin-right: 5vw !important;
      }
    }
  }
}
