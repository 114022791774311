@import "../../../styles/variables";

.rangeInputs {
  display: flex;
  margin-bottom: 17px;

  div:first-child {
    margin-right: 7px;
  }
}

$thumbRadius: 18px;
.rangesWrapper {
  position: relative;
  width: 173px;

  & > :first-child {
    display: block;
    margin: 0 0 14px 0px;
  }
  :global {
    .MuiSlider-root {
      color: transparent;
      width: calc(100% - #{$thumbRadius/2});
    }

    .MuiSlider-rail {
      height: 3px;
      border-radius: 3px;
      background-color: $dark-5;
    }

    .MuiSlider-track {
      height: 3px;
      border-radius: 3px;
      background-color: $primary;
    }

    .MuiSlider-thumb {
      width: $thumbRadius;
      height: $thumbRadius;
      margin-top: -7.5px;
      background-color: $primary;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }

      &::after {
        display: block;
        content: "";
        width: 8px;
        height: 8px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        background-color: #fff;
      }
    }

    .Mui-focusVisible {
      box-shadow: none !important;
    }

    .MuiSlider-valueLabel {
      display: none;
    }

    .PrivateValueLabel-open-2 {
      box-shadow: none;
    }
  }
}

.rangeNumbers {
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
}

.slider {
  display: flex;
  flex-direction: column;
}

.sliderBar {
  width: 100%;
  height: 2px;
  background-color: grey;
  position: relative;

  span {
    position: absolute;
    left: calc(50% - 10px);
    top: -20px;
  }
}
