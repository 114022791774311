@import "../../../../styles/variables";

.wrapper {
  background-color: $light;
  border-radius: $defaultBorderRadius;
  padding: 20px 10px 10px 10px;
  &__error {
    border: 1px solid $red;
  }
}

.table {
  width: 100%;
  border-spacing: 0px;
  & > thead {
    background-color: $primary;
    color: $light;
    & > tr > td {
      padding: 6px 17px 6px 17px;
    }
  }
  & > tbody {
    & > tr > td {
      border-bottom: 1px solid $light-6;
      border-left: 1px solid $light-6;
      border-right: 1px solid $light-6;
      padding: 6px 17px 6px 17px;
      & > input[type="radio"]::before {
        content: "";
        width: 10px;
        height: 10px;
        background-color: $primary;
        color: $primary;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
      }

      & > input[type="radio"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: #fff;
        /* Not removed via appearance */
        margin: 0;
        font: inherit;
        color: $dark-4;
        width: 18px;
        height: 18px;
        border: 1px solid $dark-4;
        border-radius: 50%;
        display: grid;
        place-content: center;
        cursor: pointer;
      }
      & > input[type="radio"]:checked::before {
        transform: scale(1);
      }
    }
  }
}

.label {
  padding: 1px 6px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: $dark-8;
  border-radius: 2px;
  position: absolute;
  background: $light;
  z-index: 3;
  visibility: hidden;
  opacity: 0.1;
  transition: 0.2s all ease-in-out;
  transform: translateY(-30px);
  &[data-active="true"] {
    background: $light-3;
    color: $dark-8;
    font-size: 10px;
    transform: translateY(-30px);
    opacity: 1;
    visibility: visible;
    display: block;
  }

  &__white {
    &[data-active="true"] {
      background: $light-3;
      color: $dark-8;
      font-size: 10px;
      transform: translateY(-30px);
      opacity: 1;
      visibility: visible;
      display: block;
      background: $light;
    }
  }

  &[required] {
    padding-right: 12px;
  }

  &[required]::after {
    content: "*";
    position: absolute;
    color: red;
    right: 3px;
    top: 2px;
    font-size: 12px;
    font-weight: 600;
    background: $light;
  }
  &[error] {
    border: 1px solid red;
  }
}

.addButton {
  margin-top: 20px;
  > button {
    width: 137px;
  }
}
.errorMsg {
  color: $red;
  margin-left: 20px;

  bottom: -18px;
  left: 0;
}
