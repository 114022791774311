@import "../../../../styles/variables";
@import "../../../../styles/scroll.scss";

.Wrapper {
  position: relative;
  width: 100%;
  min-height: 388px;
  max-height: 858px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  background-color: $dark-7;
  padding: 0 107px 0 17px;
}

.chat {
  @extend .scroll;

  margin-top: 49px;
  max-height: 736px;
  overflow-y: auto;
  padding-left: 9px;
}

.sendMessageForm {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 15px;

  & > :last-child {
    margin: 0;
    max-width: 140px;
  }

  label {
    padding: 0;
    margin: 0;
    margin-right: 20px;
  }

  button {
    margin: 0;
    padding: 0;
    max-width: 137px;
    margin-left: 17px;
    order: 1;
  }
}
.div {
  display: flex;
  align-items: center !important;
}

.person {
  position: absolute;
  height: 39px;
  left: 0;
  width: 100%;
  border-bottom: $chat-border;
  display: flex;
  align-items: center;
  padding-left: 17px;
  border-bottom: 1px solid $chat-border;
}

.red {
  position: absolute;
  top: 50%;
  height: 20px;
  width: 100%;
  text-align: center;
}
