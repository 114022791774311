@import "../../../styles/main";

.status {
  border-radius: $defaultBorderRadius;
  text-align: center;
  padding: 0 24px;

  &__created {
    @extend .status;

    color: $dark;
    background-color: $light-3;
  }

  &__sent {
    @extend .status;

    color: $dark;
    background-color: $yellow;
  }

  &__approved {
    @extend .status;

    color: $light;
    background-color: $green;
  }

  &__back {
    @extend .status;

    color: $light;
    background-color: $red;
  }
}
