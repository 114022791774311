@import "../../../../../styles/variables";

.label {
  color: $dark-6;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radioButtonWrapper {
  margin-right: 17px;
}

.fillOption {
  border-radius: $defaultBorderRadius;
  padding: 4px 12px;
  margin-bottom: 8px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;

  &.correct {
    background-color: #d6f6f4;
  }

  &.incorrect {
    background-color: #ffdede;
  }
}

.pairRow {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 100%;
  margin-bottom: 10px;
}

.pairAnswer {
  border-radius: $defaultBorderRadius;
  border: 1px solid #b9b4b4;
  background-color: #f7f7f7;
  width: 150px;
  max-width: 200px;
  text-align: center;
}

.arrows {
  width: 48px;
  display: inline-block;
  margin: auto 50px auto 50px;
  height: 1px;
  border: none;
  background-color: #a1a1a1;
  &::before {
    display: block;
    position: relative;
    content: " ";
    border-top: 1.5px solid #a1a1a1;
    border-right: 1.5px solid #a1a1a1;
    width: 10px;
    height: 10px;
    transform: rotate(225deg);
    bottom: 4px;
  }
  &::after {
    display: block;
    position: relative;
    content: " ";
    border-top: 1.5px solid #a1a1a1;
    border-right: 1.5px solid #a1a1a1;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    left: 38px;
    bottom: 14px;
  }
}

.pairWrapper {
  width: 80%;
}

.validOrderAnswer {
  background-color: #f7f7f7;
  border-radius: $defaultBorderRadius;
  text-align: center;
  width: 30%;
}

.validOrderNumber {
  border: 1px solid #414141;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  color: #414141;
  width: 18px;
  height: 18px;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
}

.validOrderRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 17px;
  margin-bottom: 10px;
}
