@import "../../../../styles/variables";

.main {
  background-color: $light-1;
  padding: 55px 0;
}

.padTop90 {
  padding-top: 90px;
}

.padBottom0 {
  padding-bottom: 0;
}
