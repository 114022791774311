@import "../../../../styles/variables";

.receivedMessage {
  min-height: 92px;
  max-width: 1024px;
  background-color: $light;
  display: flex;
  margin-bottom: 20px;
  padding-right: 100px;

  img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin: 17px 11px 0 16px;
  }

  div {
    display: flex;
    flex-direction: column;

    & > :first-child {
      word-break: break-word;
      margin: 28px 0 10px 0;
    }
    & > :last-child {
      padding-bottom: 10px;
    }
  }
}

.sendedMessage {
  @extend .receivedMessage;

  padding-left: 100px;
  padding-right: 0;
  background-color: $dark-7;

  div {
    flex: 1;

    & > :first-child {
      align-self: flex-end;
    }

    & > :last-child {
      align-self: flex-end;
    }
  }

  img {
    order: 1;
    margin: 17px 16px 0 11px;
  }
}
