@import "../../../styles/variables";
@import "../../../styles/scroll.scss";

.dropDownNav {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 190px;

  .switchUserButton {
    width: 100%;
    z-index: 2;

    & > button > span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: left;
    }
  }

  .options {
    z-index: 4;
    margin: 41px 0 0 0;
    padding: 17px 5px;
    position: absolute;
    background: $light;
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
    border-radius: $defaultBorderRadius;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    min-width: 380px;
    border: 1px;
    max-height: 365px;
    overflow-y: scroll;
  }

  .open {
    visibility: visible;
    opacity: 1;
  }

  li {
    list-style: none;
    padding: 7px 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.currentOption {
  background-color: $primary-t;
  border-radius: $defaultBorderRadius;
  cursor: default;

  &:hover {
    color: $dark;

    svg {
      fill: $dark;

      path {
        fill: $dark;
      }
    }
  }
}

.optionContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.notification {
  display: flex;
  gap: 5px;
  align-items: center;

  &__dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $primary;
  }
}


