@import "../../../styles/main.scss";

.pdfButtonsAdditional {
  position: fixed;
  bottom: 40px;
  left: 50%;
  display: flex;
  grid-gap: 20px;
  transform: translateX(-50%);
  background-color: $light-2;
  border-radius: 5px;
  padding: 7px;
}

.pdfButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-basis: 100%;
  margin: 20px 0;
  z-index: 2;
}
