@import "../../../styles/base.scss";
@import "../../../styles/variables";

.Wrapper {
  padding: 55px 0;
  background-color: $dark-7;
}

.mainContent {
  @extend .base-layout;
  display: flex;
  width: 100%;
}

.itemsWrapper {
  width: 100%;
  height: 100%;
}

.itemsWrapper {
  background-color: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 30px 20px 40px 26px;
}
