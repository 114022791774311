@import "../../../styles/variables";

.background {
  position: absolute;
  height: 100%;
  width: 25%;
  border-radius: 5px;
  left: 0;
  top: 0;
  background: $primary;
}

.background2 {
  @extend .background;
  transition: 0.1s width ease-in-out;
}

.range {
  position: relative;
  display: flex;
  box-shadow: 0px 0px 1px 0px $dark-2;
  border-radius: 6px;
  width: 100%;
  height: 19px;
  justify-content: flex-end;
  cursor: pointer;
  background-color: #fff;

  &:active {
    cursor: grabbing;
  }

  &__button {
    max-width: 120px;
    position: relative;
    border: none;
    background: transparent;
    flex: 1 1 auto;
    outline: none;

    &_dot {
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.range .dot {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
}

.firstDot {
  @extend .dot;
  fill: #00a094;
  &[data-active="true"] {
    fill: #fff;
  }
}

.secondDots {
  @extend .dot;
  fill: #00a094;

  &[data-active="true"] {
    fill: #fff;
  }

  &[data-blocked="true"] {
    fill: $lvl-0;
  }
}

.thirdDots {
  @extend .dot;
  fill: #00a094;

  &[data-active="true"] {
    fill: #fff;
  }

  &[data-blocked="true"] {
    fill: $lvl-0;
  }
}

.dotActive {
  @extend .dot;
  fill: #00a094;
  transition: 0.3s fill ease-in-out;

  &[data-active="true"] {
    fill: #fff;
  }

  &[data-blocked="true"] {
    fill: $lvl-0;
  }
}
