@import "../../../styles/variables";
@import "../../../styles/scroll.scss";

.filterPanelWrapper {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  border-radius: $defaultBorderRadius;
  background-color: #fff;
  padding: 20px 0 31px 0;
  min-width: 233px;
  max-width: 233px;
  margin-right: 0;
  height: 100%;
  @media (max-width: 1400px) {
    margin-right: 40px;
  }
}

.filterPanelPopup {
  @extend .scroll;

  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  max-height: 471px;
}

.filterPanelHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  margin-bottom: 35px;
}

.filters > div:not(:last-child) {
  margin-bottom: 32px;
}

.buttons {
  display: flex;
  align-items: center;
  align-self: center;
}
