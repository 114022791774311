@import "../../../styles/variables";

.btnPrimary::before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: $light;
  color: $primary;
  border-radius: 50%;
  transform: scale(0);
  border: 6px solid $primary;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

.btnPrimary {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: $primary;
  width: 18px;
  height: 18px;
  border: 1px solid $dark-4;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}
.btnPrimary:checked::before {
  transform: scale(1);
}

.btnSecondary::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: $light;
  color: $primary;
  border-radius: 50%;
  transform: scale(0);
  border: 5px solid $primary;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

.btnSecondary {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: $primary;
  width: 15px;
  height: 15px;
  border: 1px solid $primary;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}
.btnSecondary:checked::before {
  transform: scale(1);
}
