@import "../../../../styles/variables";

.Primary {
  background-color: $primary;
  padding: 10px;
  display: table-cell;
  font-weight: 400;
  color: $light;
  font-size: 12px;
  border: 1px solid $light-6;
  text-align: center;
}

.LightText {
  @extend .Primary;

  text-align: left;
  color: $dark-6;
  background-color: $light-1;
}

.LightCheckbox {
  @extend .Primary;

  background-color: $light-1;

  & > div {
    display: inline-block;
  }
}
