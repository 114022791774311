@import "../../../../styles/base.scss";
@import "../../../../styles/variables";

.Wrapper {
  @extend .base-layout;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.test {
  display: flex;
  & > :first-child {
    margin-top: 40px;
  }
}

.filter {
  height: 450px;
  width: 233px;
  background-color: $light;
  margin-top: 41px;
}

.statisticsContainer {
  display: flex;
  justify-content: space-around;
  gap: 46px;
}
