@import "../../../styles/variables";

.inputWrapper {
  position: relative;
}

.label {
  background-color: $light;
  max-width: max-content;
  font-size: 10px;
  line-height: 12px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  margin-bottom: 0px;
  margin-left: 6px;
}

.input {
  cursor: pointer;
  display: flex;
  width: 100%;
  & > label {
    width: 100%;
  }
}

.calendarIcon {
  position: absolute;
  right: 15px;
  height: 10px;
  top: 10px;
}

.datePickerWrapper {
  display: flex;
  flex-direction: column;

  :global {
    .react-datepicker {
      font-family: Lato;
      width: 331px;
      padding: 8px 19px 10px 19px;
      box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
      border: none;

      &-popper {
        z-index: 10;
      }

      &__header {
        padding: 0;
        background-color: $light;
        border-bottom: none;
        margin-bottom: 5px;
      }

      &__month {
        margin: 0;
      }

      &__current-month,
      &__header__dropdown {
        font-size: 10px;
        color: $dark-4;
        font-weight: normal;
      }

      &__header__dropdown {
        margin-top: 5px;
      }

      &__month-container {
        width: 100%;
        height: 100%;
      }

      &__month-dropdown-container,
      &__year-dropdown-container {
        margin: 0;
      }

      &__day-names {
        display: flex;
        justify-content: space-between;
        margin: 13px 0 0 0;
      }

      &__day-name {
        padding: 1px 3px;
        color: $dark-5;
        flex: 1;
        font-size: 8px;
        margin: 0;
        text-align: left;
        line-height: normal;
      }

      &__week {
        display: flex;
        margin-bottom: 5px;
      }

      &__navigation--next {
        top: 9px;
        right: 100px;
        height: 10px;
        width: 20px;
      }

      &__navigation--previous {
        top: 9px;
        left: 100px;
        height: 10px;
        width: 20px;
      }

      &__navigation-icon--next {
        margin: auto;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 3px;
        height: 3px;
        border-top: 0.7px solid $dark-5;
        border-left: 0.7px solid $dark-5;
        transform: rotate(135deg);

        &::before {
          border: none;
          cursor: pointer;
          content: "";
          display: block;
          width: 0.5px;
          height: 17px;
          background-color: $dark-5;
          transform: rotate(-45deg) translate(3.7px, 0);
          left: 3px;
          top: 0px;
          position: absolute;
        }
      }

      &__navigation-icon--previous {
        margin: auto;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 3px;
        height: 3px;
        border-top: 0.7px solid $dark-5;
        border-left: 0.7px solid $dark-5;
        transform: rotate(-45deg);

        &::before {
          border: none;
          cursor: pointer;
          content: "";
          display: block;
          width: 0.5px;
          height: 17px;
          background-color: $dark-5;
          transform: rotate(-45deg) translate(3.7px, 0);
          left: 3px;
          top: 0px;
          position: absolute;
        }
      }

      &__month-read-view--down-arrow,
      &__year-read-view--down-arrow {
        display: none;
      }

      &__day {
        padding: 1px 3px;
        flex: 1;
        color: $dark-4;
        width: 1rem;
        line-height: normal;
        font-size: 8px;
        margin: 0;
        text-align: left;
        border-radius: 0;
        background-color: $light;

        &--outside-month {
          color: $dark-5;
        }

        &:hover {
          border-radius: 0;
          background-color: $range;
        }

        &--in-range {
          background-color: $range;
        }

        &--range-end {
          color: $light;
          background-color: $ends;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;

          &:hover {
            background-color: $ends;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
        }

        &--selected {
          background-color: $ends;
          border-radius: 3px;
          color: $light;
        }

        &--range-start {
          color: $light;
          background-color: $ends;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;

          &:hover {
            background-color: $ends;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
        }

        &--in-selecting-range {
          border-radius: 0;
          background-color: $range !important;
        }

        &--today {
          font-weight: 800;
          text-shadow: 0 0 1px #000;
        }
      }

      &__triangle {
        left: -20px !important;

        &:after {
          border-bottom-color: $light !important;
        }
      }
    }
  }
}

.dateRanges {
  display: flex;
}

.dateRanges > div:last-child {
  margin-left: 7px;
}

.inputDatePicker {
  @extend .datePickerWrapper;

  position: relative;
  width: 100%;

  :global {
    .react-datepicker {
      width: 170px;
      top: 5px;
      left: 25%;

      &__navigation--next {
        top: 9px;
        right: 10px;
        height: 10px;
        width: 20px;
      }

      &__navigation--previous {
        top: 9px;
        left: 10px;
        height: 10px;
        width: 20px;
      }

      &-wrapper {
        height: 32px;
        margin-bottom: 36px;
      }
    }
  }

  &[data-no-label="true"] {
    :global {
      .react-datepicker-wrapper {
        margin-bottom: 0;
      }
    }
  }
}

.inputDatePickerEnd {
  @extend .inputDatePicker;

  :global {
    .react-datepicker {
      top: 205px;
      left: 100%;
    }
  }
}

:global {
  .DayPicker-wrapper {
    background-color: $light;
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
    border-radius: 5px;
  }
  .DayPicker-Caption {
    font-size: 10px;
    color: $dark-4;
  }
}
