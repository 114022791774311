@import "../../../styles/main";

.step {
  background: #f9f9f9;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  @extend .base-layout;
}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
}
