@import "../../../styles/main";
@import "../../organisms/Table/Table.module";

.table {
  @extend .table;

  & th {
    width: 280px;
    padding: 0 15px;
  }

  & td > span {
    margin-right: 6px;
  }
}

.attchedFile {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
