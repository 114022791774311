@import "../../../styles/variables";
@import "../../../styles/scroll.scss";

.TextWrapper {
  position: relative;
  // color: $dark-2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  // min-width: 100px;

  &__lineClamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &[data-secondary="true"] {
    color: $secondary;
  }

  &[data-banner="true"] {
    color: $light;

    &:hover > span > span:not(:only-child):first-child {
      color: transparent;
    }
  }

  &[data-banner="true"] &__lineClamp {
    color: $light-t;

    &:hover {
      color: transparent;
    }
  }

  &:hover {
    .truncated {
      position: absolute;
      overflow: auto;
      display: block;
      background-color: $light;
      left: -10px;
      top: 0px;
      padding: 0 10px;
      z-index: 2;
      border-radius: 5px;
      box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
      word-break: normal;
      // min-width: 100px;
      min-width: calc(100% + 20px);
      min-height: 100%;
      max-height: 250px;
      @extend .scroll;

      &[data-banner="true"] {
        background: rgba(104, 104, 104, 0.5);
        backdrop-filter: blur(10px);
        border-radius: 10px;
        min-width: calc(100% + 20px);
        left: -10px;
        top: 0;

        & > span {
          color: $light;
        }
      }
    }
  }
}

.truncated {
  overflow: hidden;
  display: none;
}
