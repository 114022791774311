@import "../../../styles/main";

.jobPositionChartWrapper {
  @extend .base-layout;

  background: #fff;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}
