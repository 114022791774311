@import "../../../styles/variables";

.navigation {
  position: absolute;
  top: 0;
  height: 100%;
  width: 53px;
  padding: 0 10px;

  &__prev {
    left: 20px;

    button {
      padding: 8px 13px 7px 10px;
    }
  }

  &__next {
    right: 20px;

    button {
      padding: 8px 10px 7px 13px;
    }
  }

  button {
    position: absolute;
    top: calc(50% - 33px / 2);
    height: 33px;
    width: 33px;
    border-radius: 50%;
    background-color: $light;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 4;
    transition: all 0.3s;

    path {
      stroke: $primary;
    }

    &[disabled],
    &[data-hidden="true"] {
      opacity: 0.0;
    }
  }
}


