@import "../../../../styles/variables";
@import "../../../../styles/scroll.scss";

.wrapper {
  display: flex;
  width: 100%;
  margin: auto;
  gap: 24px;
  position: relative;
  height: 522px;
}

.leftWrapper {
  min-width: 233px;
  max-width: 233px;
  height: 100%;
  background-color: $light;

  & > div {
    padding-bottom: 0;

    & > div > div:nth-child(2) {
      @extend .scroll;

      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.rightWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content max-content 1fr;
}

.selectedWrapper {
  @extend .scroll;

  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 115px;
  height: fit-content;
  margin-top: 20px;
}

.listWrapper {
  @extend .scroll;

  width: 100%;
  border-bottom-right-radius: 7px;
  z-index: 4;
  overflow-y: auto;
  margin-top: 20px;

  & > label {
    margin: 10px 20px;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &.disabled span,
    &.disabled div {
      cursor: default;
    }

    & > input[type="radio"] {
      margin-right: 17px;
    }
  }
}

.information {
  margin-top: 20px;
  text-align: center;
}
