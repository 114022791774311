@import "../../../styles/variables";
@import "../../../styles/scroll.scss";
.select {
  background-color: $light-1;
  width: 142px;
  height: 20px;
  line-height: 32px;
  position: relative;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 6px;
  border-radius: 2px;
  color: $primary;
  z-index: 2;

  span:first-child {
    width: 100%;
    padding-right: 20px;
  }

  .sortArrow {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    path {
      stroke: $primary;
    }

    svg {
      opacity: 1;
    }
  }
}

.table {
  @extend .scroll;

  padding-top: 5px;
  position: absolute;
  padding-left: 15px;

  top: 22px;
  right: 0;
  width: 142px;
  display: flex;
  flex-direction: column;
  background-color: $light;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  border-radius: $defaultBorderRadius;
  max-height: 160px;
  overflow-y: auto;

  div {
    cursor: pointer;

    &:hover {
      span {
        color: $primary;
      }
    }
  }
}

.selectedWrapper {
  @extend .scroll;
}
