@import "../../../styles/variables";

.ChartBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $secondary;
  min-width: 20%;
  p {
    font-size: 18px;
    margin-top: 20px;
    text-transform: uppercase;
    text-align: center;
  }
}

.statisticBox {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  border-radius: 3px;
  background-color: $light;
  bottom: 30px;
  right: 30px;
  transition: 0.5s opacity ease-in-out;
  opacity: 0;
}

.ChartBox:hover .statisticBox {
  opacity: 1;
}
