@import "../../../styles/variables";

.instructorSwiper {
  &__slide {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: var(--image-url);
    background-size: cover;
    background-position: center;
    border-radius: $defaultBorderRadius;
    overflow: visible;

    &__name {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 40px 20px 20px;
      height: 125px;
      background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.85) 65%, $light-1);
      display: flex;
      flex-direction: column;
      transition: all 0.3s;

      &[data-hovered="true"] {
        height: 160px;
      }
    }

    &__buttons {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      gap: 20px;
      visibility: hidden;

      &[data-visible="true"] {
        visibility: visible;
      }
    }
  }
}
