@import "../../../styles/base";

.popupItem {
  display: flex;
  gap: 10px;
  align-items: center;

  & > div:first-child {
    margin-bottom: 4px;
  }
}
