@import "../../../styles/main";

.competenceDetails {
  @extend .base-layout;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 60px;

  &__box {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    padding: 20px 40px 50px 40px;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__sliderWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 17px;

      & > button {
        margin-right: 13px;
      }

      &__slider {
        width: 360px;
        margin-right: 20px;
        height: 23px;
        padding: 2px;
      }
    }
  }

  &__course {
    & > span:first-child {
      margin-bottom: 10px;
      display: block;
    }

    &__tile {
      position: relative;
    }
  }
}
