@import "../../../styles/variables";

.searchInput {
  border: none;
  color: $dark-1;
  outline: none;
  width: 90%;
}

.searchIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchInputWrapper {
  display: flex;
  width: 100%;
  background: $light;
  border-radius: $defaultBorderRadius;
  padding: 5px 12px;
  height: 32px;
  transition: 0.1s box-shadow ease-in-out;

  &:focus-within {
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  }

  img {
    margin-right: 8px;
  }
}
