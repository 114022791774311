@import "../../../styles/variables";

.checkboxLabel {
  cursor: pointer;
  color: $dark-4;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__error {
    color: $red;
  }

  &[data-required="true"]::after {
    content: "*";
    position: absolute;
    background-color: transparent;
    left: 27px;
    top: 8px;
    font-size: 12px;
    font-weight: 600;
    color: $red;
  }
}

.hook {
  padding: 0;
  border: 0;
  display: flex;
}
