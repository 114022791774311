@import "../../../../styles/base.scss";
@import "../../../../styles/variables";

.Wrapper {
  @extend .base-layout;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.pieChartsBox {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
