@import "../../../../styles/variables";

.Wrapper {
  width: 100%;
  display: table-row;
  background-color: $light-6;

  & > :first-child {
    width: 50%;
  }
}
