@import "../../../styles/base.scss";
@import "../../../styles/variables";

.grid {
  @extend .base-layout;

  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
  height: 395px;

  & > div:first-child {
    max-width: 35%;
  }
}

.organizations {
  @extend .base-layout;

  width: 100%;
}
