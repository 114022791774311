@import "../../../styles/variables";

.tileItemInfo {
  display: flex;
  flex-direction: column;
  color: $dark-2;
  margin: 10px 0;
  max-height: 130px;
  position: relative;

  &[data-fullrow] {
    grid-column: 1/3;
  }

  & > span:first-child {
    margin-bottom: 6px;
  }

  & > span:nth-child(2) {
    padding-right: 10px;
    max-height: 106px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    & > span {
      display: inline-block;
    }
  }

  &__buttonWrapper {
    display: inline-block;
    margin-left: 10px;
  }
}
