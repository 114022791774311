@import "../../../styles/main";

.container {
  position: relative;
  height: 100vh;

  @media (max-height: 600px) {
    min-height: 100vh;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 21, 68, 0.72);
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 70px;
}

.description {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  &__subheader {
    margin-top: 20px;
  }
}
