@import "../../../styles/_variables";
@import "../../../styles/scroll";

.pdfDocument {
  position: relative;
  border-radius: 5px;
  border: 2px solid $light-2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  min-height: 900px;
  min-width: 700px;
  overflow: auto;
  @extend .scroll;
}

.pdfDocumentAdditional {
  border-radius: 5px;
  border: 2px solid $light-2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
}
