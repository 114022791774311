@import "../../../styles/variables";

.Wrapper {
  display: grid;
  position: relative;
  grid-template-columns: 100px auto;
  gap: 0 33px;
  width: 100%;
  background-color: $light;
  padding: 20px 10px 13px 20px;
  border-radius: $defaultBorderRadius;
  margin-bottom: 36px;

  div {
    display: flex;
    align-items: baseline;
  }

  &__error {
    border: 1px solid $red;
  }

  &__noMargin {
    margin: 0;
  }
}

.Image {
  width: 100px;
  height: 66px;

  &.square {
    height: 100px;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
}

.uploadInput {
  display: none;
}

.photo {
  background-color: none;
  border: 1px solid $light-7;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $dark-2;
  border-radius: $defaultBorderRadius;
  font-size: 12px;
  padding: 0 40px;
  cursor: pointer;
  width: 140px;
  margin-right: 25px;

  &[data-disabled="true"] {
    background-color: $light-3;
  }
}

.fileWrapper {
  display: flex;
  padding: 15px;
  @extend .Wrapper;

  div {
    margin-bottom: 0px;
  }

  &__noMargin {
    margin: 0;
    padding: 0;
  }

  & > :last-child {
    align-self: flex-end;
    margin-bottom: 0px;
  }
}

.errorMsg {
  color: $red;
  margin-left: 20px;
  position: absolute;
  bottom: -18px;
  left: 0;
}
