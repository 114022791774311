@import "../../../styles/variables";
@import "../../../styles/main";
@import '../../../styles/scroll.scss';

.NotificationDropdown {
  width: 340px;
  border-radius: $defaultBorderRadius;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  position: absolute;
  background-color: $light;
  pointer-events: auto;
}

.NotificationDropdown__wrapper {
  @extend .NotificationDropdown;
  background-color: transparent;
  opacity: 1;
  visibility: visible;
  box-shadow: none;
  right: 100px;
  top: 60px;
  pointer-events: auto;
  transition: all 0.4s;
}

.NotificationDropdown__wrapperHidden {
  @extend .NotificationDropdown__wrapper;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.NotificationDropdown__titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.5px solid $dark-3;
  padding: 16px 25px 13px 20px;
}

.NotificationDropdown__buttonWrapper {
  display: flex;
  justify-content: center;
  border-top: 0.5px solid $dark-3;
  padding: 14px 0px 14px 0px;
}

.iconButton {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.bellButton {
  @extend .iconButton;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:hover svg {
    fill: $primary;
  }

  &__counter {
    position: absolute;
    background-color: $primary;
    color: $light;
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    top: -4px;
    left: 11px;
    padding: 2px 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}

.settingsButton {
  cursor: pointer;
}

.NotificationDropdown__itemsWrapper{
@extend .scroll;

  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding-top: 15px;
  padding-bottom: 20px;
  overflow-y: scroll;
  max-height: 500px;

  &__noData {
    margin: 0 20px;
  }
}
