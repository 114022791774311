@import "../../../styles/variables";

.categories {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.types {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

:global {
  .react-chatbot-kit-chat-container {
    width: 375px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: $defaultBorderRadius;
  }

  .react-chatbot-kit-chat-message-container {
    padding-bottom: 0;
    overflow-x: initial;
    overflow-y: scroll;
    height: 417px;
  }

  .react-chatbot-kit-chat-header {
    background: $primary;
    color: $light;
    font-size: 14px;

    & > span {
      position: absolute;
      right: 5px;
      cursor: pointer;
      padding: 10px;
    }
  }

  .react-chatbot-kit-chat-bot-message {
    width: 273px;
    background-color: $light-3;
    color: $dark-6;
    font-size: 14px;
  }

  .react-chatbot-kit-chat-bot-message-arrow {
    border-right-color: $light-3;
  }

  .react-chatbot-kit-user-avatar-container {
    background: none;
  }

  .react-chatbot-kit-user-chat-message {
    background-color: $primary;
    color: $light;
    font-size: 14px;
  }

  .react-chatbot-kit-user-chat-message-arrow {
    border-left-color: $primary;
  }

  .react-chatbot-kit-chat-btn-send {
    background-color: $primary;
  }

  .chatbot-loader-container {
    g {
      fill: $primary,
    }
  }
}