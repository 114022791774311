.headerWithButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 15px;

    & > * {
      min-width: 137px;
    }
  }
}
