@import "../../../styles/variables";
@import "../../../styles/scroll.scss";

.Wrapper {
  position: relative;
}
.tableWrapper {
  padding: 18px 20px;
  position: absolute;
  top: 37px;
  width: 100%;
  background-color: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: $defaultBorderRadius;
  z-index: 4;
}

.table {
  @extend .scroll;

  position: relative;
  max-height: 160px;
  width: 100%;
  z-index: 4;
  background-color: $light;
  overflow: hidden auto;
  scroll-behavior: smooth;

  &__hide {
    opacity: 0;
  }
}
.tableElement {
  align-items: center;
  display: flex;
  cursor: pointer;
  height: 32px;

  &__active {
    span {
      color: $primary;
    }
  }

  span > div {
    display: inline-block;
  }

  & > div:first-child {
    margin-right: 10px;
  }
}

.errorMsg {
  color: $red;
  margin-left: 20px;
  position: absolute;
  bottom: -15px;
  left: 0;
}
