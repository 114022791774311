@import "../../../styles/base";
@import "../../../styles/scroll";

.Wrapper {
  @extend .scroll;

  height: 100%;

  &[data-idp="true"] {
    margin: auto;
    overflow-x: initial;
  }
}

.jobWrapper {
  @extend .scroll;

  display: flex;
  justify-content: center;
  width: 100%;
  //overflow-x: auto;
  background-color: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 9px;

  &[data-idp="true"] {
    border-radius: 0;
  }

  &[data-idp="false"] {
    min-height: 460px;
  }

  &[data-active="true"] {
    justify-content: flex-start !important;
  }
}

.firstJob {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 28px;

  button {
    min-width: 137px;
    margin-top: 24px;
  }
}

.chart {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  //transform: translateX(30px);
  ul {
    &::before {
      content: none;
    }
  }
}

.chartWrapper {
  @extend .scroll;

  width: fit-content;
  min-width: 900px;
  margin: auto;
  position: relative;
  overflow-x: scroll;
  max-width: 100%;
  background: white;
  border-radius: 9px;
}
