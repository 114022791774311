@import "../../../styles/variables";

.chartWrapper {
  width: 100%;
  background-color: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding-top: 70px;
  .apexcharts-series path {
    clip-path: inset(5% 5% 5% 0% round 8px);
  }

  .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
    clip-path: inset(5% 5% 5% 0% round 8px);
  }
  //:global {
  //  .apexcharts-rangebar-area {
  //    clip-path: inset(5px round 7px 7px 7px 7px);
  //  }
  //}

  .apexcharts-text .apexcharts-yaxis-label {
    color: red;
  }
}

.chartWrapperNoData {
  background-color: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
