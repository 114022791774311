@import "../../../styles/variables";

.input {
  font-size: 12px;
  padding: 6px;
  background-color: #fff;
  border: 1px solid $light-7;
  color: black;
  transition: 180ms border ease-in-out;
  border-radius: 3px;
  width: 100%;
}

.input:focus {
  color: $primary;

  outline: 2px solid $primary;
}

.input::placeholder{
  color: $light-5;
}