.sectionTitle {
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  gap: 13px;
  grid-gap: 13px;
  & > div > svg {
    filter: brightness(0);
  }
}

.unitWrapper {
  display: flex;
  margin-bottom: 26px;
  margin-top: -7px;
}

.line {
  width: 1px !important;
  background-color: #a1a1a1;
  margin-right: 10px;
  margin-left: 25px;
}

.testClickable {
  cursor: pointer;
}

.testUnclickable {
  pointer-events: none;
}
