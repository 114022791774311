@import '../../../styles/variables';

.container {
    background-color: $light;
    padding: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    max-width: calc(100% - 2 * 105px / 2);
}

.settingsIcon {
    margin-left: 6px;
}

.header {
    display: flex;
    justify-content: space-between;
}

.notificationsContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.notificationsInfo{
    text-align: center;
}
