@import "../../../styles/variables";

.Wrapper {
  display: flex;
  align-items: center;
  min-height: 28px;
  border-radius: 3px;
  background-color: $dark-7;
  margin: 5px 5px 5px 0;
  padding-right: 5px;

  & > img,
  & > svg {
    margin-left: 11px;
  }

  & > span {
    margin: 0 11px ;
  }
}
