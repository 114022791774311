@import "../../../styles/variables";
@import "../../../styles/base.scss";

.reviews {
  width: 100%;
  position: relative;

  &__swiper {
    position: relative;

    :global {
      .swiper-slide {
        width: auto;

        &:first-child {
          margin-left:  var(--h-margins);
        }

        &:last-child {
          margin-right: var(--h-margins) !important;
        }
      }
    }
  }
}
