@import "../../../styles/main";

.questionBox {
  display: flex;
  flex-direction: column;
  background-color: $light;
  padding: 40px 40px 45px 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  margin-bottom: 75px;
}

.ProgressBar__centering {
  display: flex;
  justify-content: center;
}

.ProgressBar__wrapper {
  max-width: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;

  > :first-child {
    padding: 50px 0 5px 0;
  }
}

// Arrows
.arrow {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  position: absolute;
  width: 11px;
  height: 11px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  border-radius: 1px;
  transform: rotate(135deg);
}

.rightArrow {
  @extend .arrow;
}

.leftArrow {
  @extend .arrow;

  transform: rotate(-45deg);
}

.arrowButton {
  position: relative;
  display: flex;
  height: 15px;
  width: 15px;
  margin-left: 14px;

  &:first-child {
    margin-left: 0;
    margin-right: 14px;
  }

  button {
    height: 100%;
    background: none;
    border: none;
  }
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  gap: 20px;

  > button:last-child {
    margin-left: 30px;
  }
}

.pageNumber {
  display: flex;
  > * {
    margin-right: 7px;
  }

  > button:last-child {
    margin: 0;
  }
}

.currPage {
  button {
    background-color: $primary;
    span {
      color: #fff;
    }
  }
}

.answeredPage {
  button {
    background-color: #afdad7;
    border: none;
    span {
      color: #fff;
    }
  }
}

.afterAnswer {
  button {
    background-color: #afdad7;
    border: #afdad7;
    span {
      color: #fff;
    }
  }
}

.bullet {
  display: flex;
}

.paginationDots {
  margin-left: 7px;
}
