@import "../../../styles/main.scss";
@import "../../../styles/variables";

.section {
  background-color: $light-1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  flex: 1 auto;
  & > :last-child {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.link {
  cursor: pointer;
}

.main {
  @extend .base-layout;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 60px;
  background-color: $light-1;
  height: 100%;
  gap: 50px;
}
