@import "../../../styles/base.scss";
@import "../../../styles/variables";
.Wrapper {
  @extend .base-layout;

  display: flex;
  margin-right: 0;
}

.main {
  background-color: $light-1;
}
