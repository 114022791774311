@import "../../../styles/main.scss";

.wrapper {
  @extend .base-layout;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  background: transparent;

  > :nth-child(2) {
    margin-bottom: 55px;
  }
}

.stepsContainer {
  display: flex;
  justify-content: center;
}

.homeButton {
  display: flex;
  justify-content: flex-end;
  margin: 16px;
}
