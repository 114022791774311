@import "../../../../styles/_variables";

.Wrapper {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  width: 100%;

  & > :first-child {
    margin-right: 20px;
  }
  & > :nth-child(2) {
    width: 84px;
  }
}

.content {
  width: 100%;
  display: flex;

  table {
    border-collapse: collapse;

    td, th {
      border: 1px solid $dark;
      padding: 5px;
    }

    th {
      background: $dark;
      color: $light;
    }

    ul {
      padding-left: 15px;
    }
  }
}
