.content {
  display: flex;
  flex-direction: row;
  gap: 40px;

  & > * {
    flex: 1;
  }
}

.qrCodeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formWrapper {
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 56px;
  }
}

.descriptionWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;

}