@import "../../../styles/variables";

.socialStars {
  position: relative;
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    padding-left: 10px;
  }
}

.starIcon {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
