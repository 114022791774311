@import "../../../../styles/variables";
@import "../../../../styles/scroll.scss";

.Wrapper {
  width: 100%;
  height: 100%;
  max-width: 650px;
  min-height: 389px;
  max-height: 859px;
  margin-top: 50px;
  z-index: 3;
  display: flex;
  flex-direction: column;
}

.chatUsers {
  @extend .scroll;

  position: relative;
  max-height: 736px;
  margin-right: 9px;
  overflow-y: auto;

  & > :nth-child(odd) {
    background-color: $light;
  }
}

.information {
  background-color: $light-1 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputWrapper {
  background-color: $light-1 !important;
  display: flex;
  align-items: center;
  margin-bottom: 17px;

  button {
    max-width: 137px;
    margin: 0 25px 0 26px;
  }
}

.groupChats {
  border-top: 1px solid $chat-border;
  display: flex;
  height: 63px;
  align-items: center;
  position: relative;
  margin-top: auto;

  span {
    margin-right: 45px;
  }
}

.groupChatbutton {
  position: absolute;
  margin-left: auto;
  margin-right: 29px;
  padding: 15px 4px 0 4px;
  right: 0;
}

.groupChatItem {
  width: 86px;
  height: 61px;
  margin-right: 42px;
  border: 1px solid rgb(208, 199, 199);
  cursor: pointer;
  object-fit: cover;
}

.groupChatsTable {
  @extend .scroll;

  overflow-y: auto;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 371px;
  max-height: 222px;
  transition: all 0.3s;
}

.scrollWrapper {
  padding-right: 12px;
  right: -330px;
  bottom: 50px;
  position: absolute;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  background-color: $light;
}

.groupChatItemIntable {
  width: 86px;
  height: 62px;
  margin-bottom: 23px;
  border: 1px solid rgb(208, 199, 199);
  cursor: pointer;
}
