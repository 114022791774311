@import "../../../styles/variables";

$rowPadding: 24px;
$levelWidth: 120px;
$keyCompetencePadding: 8px;

.competence {
  max-width: 700px;
  width: 100%;
  background-color: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  position: relative;

  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: calc(100% - 5px);
    height: 2px;
    background: $primary;
    z-index: 1;
    border-radius: 3px;
    opacity: 0;
    transition: 0.3s opacity ease-in-out;
  }

  &:hover {
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  }

  &:hover::before {
    opacity: 1;
  }

  &__box {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
  }

  &__verificationBox {
    display: flex;
    align-items: center;

    & > span {
      width: 53px;
      margin-right: 24px;
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  &__titleWrapper {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
      max-width: 550px;
    }
  }

  &__informationBox {
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    margin-bottom: 20px;
  }

  &__informationText {
    span:nth-child(1) {
      margin-right: 4px;
    }
  }

  &__priority {
    padding: 28px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $dark-3;

    > span {
      margin-right: min(20px);
    }
  }
}

.buttons__container {
  display: flex;
  max-width: 512px;
  justify-content: space-between;
  flex-grow: 1;
}

.knowledgeLvl {
  position: relative;
  padding: 19px 0 46px 0;
  border-top: 1px solid $dark-3;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > span {
    display: block;
    margin-right: min(20px);
  }

  &__row {
    padding: 0 $rowPadding;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.lvlSlider {
  width: calc(3 * $levelWidth);
}

.currentLvlSlider {
  width: calc(4 * $levelWidth);
}

.keyCompetence {
  position: absolute;
  right: calc($rowPadding - $keyCompetencePadding + $levelWidth * (3 - var(--key-competence-level)));
  top: 35px;
  bottom: 5px;
  width: calc($levelWidth + 2 * $keyCompetencePadding);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__frame {
    border: 2px solid $red-1;
    border-radius: $defaultBorderRadius;
    width: calc($levelWidth + 2 * $keyCompetencePadding);
    height: 100%;
  }

  &__description {
    white-space: nowrap;
  }
}
