@import "../../../styles/base.scss";
@import "../../../styles/variables";

.main {
  @extend .base-layout;
}

.wrapper {
  background-color: $light-1;
}

.licensesWrapper {
  background-color: $light;
  border-radius: $defaultBorderRadius;
  margin-bottom: 70px;
}
