@import "../../../styles/main";

.homeButton {
  @extend .base-layout;

  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

