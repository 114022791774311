@import "../../../styles/variables";

.idpComment {
  display: block;
  margin-bottom: 15px;

  & > span {
    margin-right: 5px;
  }
}
