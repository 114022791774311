@import "../../../styles/scroll.scss";

.selectedWrapper {
  @extend .scroll;

  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 115px;
  height: fit-content;
  margin-bottom: 25px;

  & > div {
    max-width: 250px;

    & > span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
    }
  }
}
