//  font sizes
@import "../../../styles/variables";
@import "../../../styles/scroll.scss";

.s48 {
  font-size: 48px;
}

.s40 {
  font-size: 40px;
}

.s32 {
  font-size: 32px;
}

.s24 {
  font-size: 24px;
}

.s20 {
  font-size: 20px;
}

.s18 {
  font-size: 18px;
}

.s15 {
  font-size: 15px;
}

.s14 {
  font-size: 14px;
}

.s12 {
  font-size: 12px;
}

.s10 {
  font-size: 10px;
}

.s9 {
  font-size: 9px;
}

.s8 {
  font-size: 8px;
}

.s6 {
  font-size: 6px;
}

.mb26 {
  margin-bottom: 26px !important;
}

.mb27 {
  margin-bottom: 27px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

// font weights

.w300 {
  font-weight: 300;
}

.w400 {
  font-weight: 400;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

.w800 {
  font-weight: 800;
}

.w900 {
  font-weight: 900;
}

// line-heights
.lh64 {
  line-height: 64px;
}

.lh58 {
  line-height: 58px;
}

.lh32 {
  line-height: 32px;
}

.lh30 {
  line-height: 30px;
}

.lh24 {
  line-height: 24px;
}

.lh22 {
  line-height: 22px;
}

.lh20 {
  line-height: 20px;
}

.lh18 {
  line-height: 18px;
}

.lh14 {
  line-height: 14px;
}

.lh12 {
  line-height: 12px;
}

// colors

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

// light

.light {
  color: $light;
}

.light-t {
  color: $light-t;
}

.light-5 {
  color: $light-5;
}

.light-8 {
  color: $light-8;
}

.light-9 {
  color: $light-9;
}

// dark

.dark {
  color: $dark;
}

.dark-1 {
  color: $dark-1;
}

.dark-2 {
  color: $dark-2;
}

.dark-3 {
  color: $dark-3;
}

.dark-4 {
  color: $dark-4;
}

.dark-5 {
  color: $dark-5;
}

.dark-6 {
  color: $dark-6;
}

.dark-7 {
  color: $dark-7;
}

.dark-8 {
  color: $dark-8;
}

.black {
  color: #000;
}

.white {
  color: #fff;
}

.purple {
  color: $purple;
}

// Red

.red {
  color: $red;
}

.green {
  color: $green;
}

.green-4 {
  color: $green-4;
}

.yellow {
  color: $yellow;
}

.orange {
  color: $orange;
}

//font families

.montserrat {
  font-family: "Montserrat", sans-serif;
}

// text transform

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.strikethrough {
  text-decoration: line-through;
}

.italic {
  font-style: italic;
}

.nowrap {
  white-space: nowrap;
}

.wordBreak {
  word-break: break-word;
}

.lineClamp1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp2 {
  @extend .lineClamp1;
  -webkit-line-clamp: 2;
}

.lineClamp3 {
  @extend .lineClamp1;
  -webkit-line-clamp: 3;
}

.lineClamp4 {
  @extend .lineClamp1;
  -webkit-line-clamp: 4;
}

.stroke {
  text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff,
    0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff,
    0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff,
    0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff,
    0 0 1px #fff;
}

.shadow20 {
  text-shadow: 0 0 20px $light;
}
