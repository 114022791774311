@import "../../../styles/variables";

.notification {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-bottom: 30px;
  align-items: center;
}

.notificationImage {
  grid-area: image;
  max-width: 60px;
  max-height: 60px;
  height: 60px;
  width: 60px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.notificationContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  grid-gap: 8px;
}
