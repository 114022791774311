@import "../../../styles/variables";

.checkboxesWrapper {
  display: inline-block;
  background-color: #fff;

  > span {
    display: block;
    margin-bottom: 12px;
  }

  & > label {
    margin-bottom: 12px;
  }

  & > label:last-child {
    margin-bottom: 0;
  }
}
