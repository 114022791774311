@import "../../../styles/variables";

.DropDownLink {
  color: $dark;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s color ease-in-out;

  &:hover {
    color: $primary;

    svg {
      fill: $primary;
      path {
        fill: $primary;
      }
    }
  }

  svg {
    margin-right: 8px;
    transition: 0.2s fill ease-in-out;
    fill: $dark;
    width: 12px;
    height: 12px;
  }

  path {
    transition: 0.2s fill ease-in-out;
  }
}
