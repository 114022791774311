@import "../../../styles/variables";

.Wrapper {
  min-height: 325px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  background-color: $light;
  padding-top: 1px;
}

.CompetencesTitle {
  margin-left: 20px;
  color: $secondary;
  font-size: 20px;
  font-weight: 800;
}

.ChartWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  flex-wrap: wrap;

  > :first-child {
    margin-right: 250px;
  }
}
