@import "../../../../styles/variables";

.Wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $popupBackground;
  z-index: 6;
  transition: 0.3s opacity ease-in-out;
}

.reloadWrapper {
  position: relative;
}

.Popup {
  width: 100%;
  min-width: 500px;
  max-height: calc(min(700px, 95vh));
  max-width: 630px;
  background-color: $light-1;
  border-radius: $defaultBorderRadius;
  padding: 28px 50px 40px 50px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  transform: opacity 0.3s;
  overflow-y: auto;

  & > :first-child {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 46px;
  }

  &[data-visible="false"] {
    display: none;
  }
}
