@import "../../../styles/variables";

.workgroupMemberTile {
  position: relative;
  background: $light;
  width: 100%;
  max-width: 308px;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  transition: 0.3s box-shadow ease-in-out;

  &:hover {
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: calc(100% - 5px);
    height: 2px;
    background: $primary;
    z-index: 1;
    border-radius: 3px;
    transition: 0.3s opacity ease-in-out;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  &__img {
    width: 116px;
    height: 94px;
    object-fit: cover;
  }

  &__nameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__box {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    margin-bottom: 30px;
    gap: 10px;
  }

  &__information {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    max-width: 140px;
    span {
      margin-bottom: 4px;
    }
  }

  &__license {
    display: flex;
    margin-bottom: 50px;
  }
}

.dot {
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: 20px;
  right: 30px;
  border-radius: 50%;
  border: 1px solid #414141;
  transition: 0.3s all ease-in-out;
  cursor: pointer;

  &Clicked {
    border: 1px solid $primary;
    background-color: $primary;
  }
}

.buttonsContainer {
  position: absolute;
  bottom: 15px;
}

.licensesContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}
