@import "../../../../styles/variables";

.statisticBar {
  height: 25px;
  display: flex;
  align-items: center;
  position: relative;
  min-width: 160px;
  width: 100%;
}

.statisticBar__background {
  height: 14px;
  position: absolute;
  max-width: 80px;
}

.statisticBar__green {
  background-color: $green;
}

.statisticBar__red {
  background-color: $red;
}

.statisticBar__left {
  right: 50%;
}

.statisticBar__right {
  left: 50%;
}

.centerLine {
  background-color: $dark-2;
  height: 100%;
  width: 1px;
  position: absolute;
  right: 50%;
}

.textContainerLeft {
  position: absolute;
  right: 50%;
  transform: translateX(-10px);
}

.textContainerRight {
  position: absolute;
  left: 50%;
  transform: translateX(10px);
}
