@import "../../../styles/base.scss";
@import "../../../styles/variables";

.Wrapper {
  @extend .base-layout;
}

.pieChartTotalWrapper {
  display: flex;
  gap: 40px;
}

.tablesContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
