@import "../../../styles/variables";
@import "../../../styles/base.scss";
@import "../../../styles/main.scss";
@import "../../../styles/scroll.scss";

.Wrapper {
  @extend .base-layout;
}

.mainContent {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.playerSection {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border: 1px solid $light-2;
  border-radius: 5px;
  padding: 50px;
  width: calc(100% - 325px);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  position: relative;
  overflow: hidden;

  & ul {
    margin: 0;
  }
}

.viedoWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;

  & > div:first-child {
    position: absolute;
  }
}

.reactPlayer {
  width: 100% !important;
  height: 100% !important;
  aspect-ratio: 16 / 9;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.pdfWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfInner {
  margin: 0 auto;
  max-width: 90%;
}

.pdfAdditionalContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

:global {
  .react-pdf__Page__textContent {
    display: none !important;
  }
  .annotationLayer {
    display: none !important;
  }
}

.box {
  @extend .base-layout;

  background: #fff;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 40px;

  & > div > ul {
    padding-left: 22px;
  }
}

.materialSection {
  flex-basis: 100%;
  margin-bottom: 67px;
}

.additionalMaterial {
  color: $dark-4;
  font-weight: 600;
  line-height: 16px;
  font-size: 14px;
  margin-bottom: 9px;
}

.materialDescription {
  margin-bottom: 21px;
}

.description {
  margin-left: 50px;
  margin-right: 50px;
  font-size: 12px;
  line-height: 24px;
  color: $dark-4;

  h1 {
    color: $secondary;
    font-weight: 800;
    font-size: 24px;
  }
  h2 {
    margin-top: 70px;
    color: $secondary;
    font-weight: 800;
    font-size: 20px;
  }
  p,
  ul {
    color: $dark-6;
    font-size: 12px;
    font-weight: 400;
  }
}


