@import "../../../styles/variables";

.level {
  display: flex;
  align-items: center;

  > span {
    margin-right: 15px;
  }
}

.levelSmall {
  @extend .level;

  img {
    width: 50px;
    height: 9px;
  }
}
