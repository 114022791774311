@import "react-date-range/dist/styles.css";
@import "../../../styles/variables";

.textWrapper {
  margin-bottom: 10px;
}

.calendar {
  position: relative;

  :global {
    .rdrMonthAndYearWrapper,
    .rdrMonths {
      position: absolute;
      width: 174px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
      background: white;
    }

    .rdrMonthAndYearWrapper {
      top: 25px;
    }

    .rdrMonths {
      top: 59px;
    }
  }

  &[data-active="false"] {
    :global {
      .rdrCalendarWrapper {
        font-size: 8px;
        width: 100%;
        background-color: white;
      }

      .rdrDateDisplay {
        margin: 0;
      }

      .rdrDateDisplayWrapper {
        background-color: transparent;
        margin: 0;
      }

      .rdrDateDisplayItem {
        position: relative;
        border-color: $primary;
        width: 83px;
        height: 24px;
        border-radius: 5px;
        box-shadow: none;

        &:first-of-type {
          &::before {
            content: "From";
            position: absolute;
            background-color: $light;
            font-size: 10px;
            padding: 0 1px;
            height: 10px;
            left: 4px;
            top: -1px;
            color: $dark;
            transform: translateY(-50%);
          }
        }

        &:last-of-type {
          &::before {
            content: "To";
            position: absolute;
            background-color: $light;
            font-size: 10px;
            height: 10px;
            left: 4px;
            top: -1px;
            padding: 0 1px;
            color: $dark;
            transform: translateY(-50%);
          }
        }

        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          right: 3px;
          top: 50%;
          transform: translateY(-50%);
          background: url("../../../assets/icons/calendar.svg") no-repeat;
          z-index: 1;
          cursor: pointer;
        }

        & input {
          height: 100%;
          text-align: left;
          padding-left: 6px;
          color: $dark-4;
          font-size: 10px;
        }
      }

      .rdrMonthAndYearWrapper {
        display: none;
      }

      .rdrMonths {
        display: none;
      }

      .rdrYearPicker {
        display: none;
      }

      .rdrWarning {
        background-color: $light;
        z-index: 2;
        position: absolute;
        font-size: 1.6em;
        line-height: 1.3em;
        top: 2px;
        right: 2px;
      }
    }
  }

  &[data-active="true"] {
    :global {
      .rdrCalendarWrapper {
        font-size: 8px;
        width: 100%;
        background-color: white;
        border-radius: 10px;
      }

      .rdrDateDisplay {
        margin: 0;
      }

      .rdrDateDisplayWrapper {
        background-color: transparent;
      }

      .rdrYearPicker {
        display: none;
      }

      .rdrMonthAndYearWrapper {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        > button i {
          display: none;
        }
      }

      .rdrNextButton {
        background: url(../../../assets/icons/DatePickerRightArrow.svg)
          no-repeat;
        background-position: 50%;
      }

      .rdrPprevButton {
        background: url(../../../assets/icons/DatePickerLeftArrow.svg) no-repeat;
        background-position: 50%;
      }

      .rdrStartEdge {
        background-color: $primary;
      }

      .rdrEndEdge {
        background-color: $primary;
      }

      .rdrMonthAndYearPickers {
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        color: $dark-4;
      }

      .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
        color: $dark-4;
      }

      .rdrDayToday .rdrDayNumber span:after {
        display: none;
      }

      .rdrMonths {
        box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
        border-radius: 5px;
      }

      .rdrMonth {
        width: 100%;
        padding: 0 15px 10px 15px;
      }

      .rdrDateDisplayItem {
        position: relative;
        border-color: $primary;
        width: 83px;
        height: 24px;
        border-radius: 5px;
        box-shadow: none;

        &:first-of-type {
          &::before {
            content: "From";
            position: absolute;
            background-color: $light;
            font-size: 10px;
            padding: 0 1px;
            height: 10px;
            left: 4px;
            top: -1px;
            color: $dark-1;
            transform: translateY(-50%);
          }
        }

        &:last-of-type {
          &::before {
            content: "To";
            position: absolute;
            background-color: $light;
            font-size: 10px;
            height: 10px;
            left: 4px;
            top: -1px;
            padding: 0 1px;
            color: $dark-1;
            transform: translateY(-50%);
          }
        }

        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          right: 3px;
          top: 50%;
          transform: translateY(-50%);
          background: url("../../../assets/icons/calendar.svg") no-repeat;
          z-index: 1;
          cursor: pointer;
        }

        & input {
          height: 100%;
          text-align: left;
          padding-left: 6px;
          color: $dark-4;
          font-size: 10px;
        }
      }

      .rdrDateDisplayItemActive {
        border-color: $primary;
      }

      .rdrWarning {
        background-color: $light;
        z-index: 2;
        position: absolute;
        font-size: 1.6em;
        line-height: 1.3em;
        top: 2px;
        right: 2px;
      }
    }
  }
}
