@import "../../../../styles/variables";
@import "../../../../styles/scroll.scss";

.wrapper {
  background-color: $light;
  border-radius: $defaultBorderRadius;
  padding: 20px 15px 18px 15px;
  margin-top: 10px;
  max-height: 120px;
  overflow-y: scroll;
  @extend .scroll;
  &__error {
    border: 1px solid $red;
  }
}

.addButton {
  margin-top: 20px;
  > button {
    width: 137px;
  }
}

.table {
  width: 100%;
  border-spacing: 0px;

  & > thead {
    background-color: $primary;
    color: $light;
    & > tr > td {
      padding: 6px 17px 6px 17px;
    }
  }
  & > tbody {
    color: $dark-6;
    & > tr > td {
      border-bottom: 1px solid $light-6;
      border-left: 1px solid $light-6;
      border-right: 1px solid $light-6;
      padding: 6px 17px 6px 17px;
    }
  }
}

.label {
  padding: 1px 6px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: $dark-8;
  border-radius: 2px;
  position: absolute;
  background: $light;
  z-index: 3;
  visibility: hidden;
  opacity: 0.1;
  transition: 0.2s all ease-in-out;
  transform: translateY(-30px);
  &[data-active="true"] {
    background: $light-3;
    color: $dark-8;
    font-size: 10px;
    transform: translateY(-30px);
    opacity: 1;
    visibility: visible;
    display: block;
  }

  &__white {
    &[data-active="true"] {
      background: $light-3;
      color: $dark-8;
      font-size: 10px;
      transform: translateY(-30px);
      opacity: 1;
      visibility: visible;
      display: block;
      background: $light;
    }
  }

  &[required] {
    padding-right: 12px;
  }

  &[required]::after {
    content: "*";
    position: absolute;
    color: red;
    right: 3px;
    top: 2px;
    font-size: 12px;
    font-weight: 600;
    background: $light;
  }
}
.errorMsg {
  color: $red;
  margin-left: 20px;

  bottom: -18px;
  left: 0;
}

.fillHolesPlaceholder {
  min-width: 100%;
  background: $light;
  border: none;
  border-radius: $defaultBorderRadius;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: $dark-4;
  margin-bottom: 20px;
  min-height: 95px;
  padding-left: 20px;
  outline: none;
  transition: 0.2s background ease-in-out;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.fillHolesWrapper{
  display: flex;
  flex-direction: column;


  &>button{
    align-self: flex-end;
    max-width: 150px;
    margin-bottom: 20px;
  }
}

.tooltip{
  background-color: $light;
  border-radius: 3px;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  display: none;
  position: absolute;
}

.clickableDiv {
  cursor: pointer;
  max-height: 14px;
  display: flex;
  align-items: center;
  margin-right: 3px;
  position: relative;

  &:hover {
    color: $primary;
    >.tooltip{
      display: block;
      color: $dark-4;
      padding: 8px 10px;
      top: 20px;
      min-width: 140px;
      z-index: 3;
    }
  }
}

.clickedAnswer {
  background: #d6f6f4;
  margin-left : 2px;
  margin-right: 5px;
  padding-left: 3px;
  padding-right: 3px;

  &:after {
    content: "x";
    font-size: 12px;
    font-weight: 300;
    color: $primary;
    margin-left: 3px;
    margin-bottom: 2px;
  }
}

.noAnswers {
  text-align: center;
}
