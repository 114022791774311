@import "../../../styles/variables";

.Chart {
  position: relative;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-table="true"] {
    height: 51px;
    width: 51px;
  }
}

.ChartBorderInside {
  position: absolute;
  width: 55px;
  height: 55px;
  border: 3px solid $secondary;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-table="true"] {
    svg {
      position: absolute;
      transform: translate(-50%, -50%) scale(0.8);
      left: 50%;
      top: 50%;
    }
  }
}

.ChartBorderOutside {
  @extend .ChartBorderInside;
  height: 70px;
  width: 70px;

  &[data-table="true"] {
    height: 51px;
    width: 51px;
  }
}

.ChartNumber {
  color: $secondary;
  z-index: 2;
  font-weight: 800;
  font-size: 24px;
}

.level {
  position: absolute;
  left: 45px;
  top: 45px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  span {
    color: $light;
  }

  &[data-table="true"] {
    left: 33px;
    top: 35px;
    height: 17px;
    width: 17px;
  }
}

.lvl0 {
  path {
    fill: $lvl-0;
  }
}

.lvl1 {
  path {
    fill: $lvl-1;
  }
}

.lvl2 {
  path {
    fill: $lvl-2;
  }
}

.lvl3 {
  path {
    fill: $lvl-3;
  }
}

.lvl4 {
  path {
    fill: $lvl-4;
  }
}

.lvl5 {
  path {
    fill: $lvl-5;
  }
}

.lvl6 {
  path {
    fill: $lvl-6;
  }
}

.lvl7 {
  path {
    fill: $lvl-7;
  }
}

.lvl8 {
  path {
    fill: $lvl-8;
  }
}

.lvl9 {
  path {
    fill: $lvl-9;
  }
}

.lvl10 {
  path {
    fill: $lvl-10;
  }
}
