@import "../../../../styles/variables";

.wrapper {
  background-color: $light;
  border-radius: $defaultBorderRadius;
  padding: 20px 10px 10px 10px;
  &__error {
    border: 1px solid $red;
  }
}

.addButton {
  margin-top: 20px;
  > button {
    width: 137px;
  }
}

.table {
  width: 100%;
  border-spacing: 0px;
  & > thead {
    background-color: $primary;
    color: $light;
    & > tr > td {
      padding: 6px 17px 6px 17px;
    }
  }
  & > tbody > tr > td {
    border-bottom: 1px solid $light-6;
    border-left: 1px solid $light-6;
    border-right: 1px solid $light-6;
    padding: 6px 17px 6px 17px;
  }
}

.validOrderNumber {
  border: 1px solid $dark-4;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  color: $dark-4;
  width: 18px;
  height: 18px;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  padding: 2px 5px;
}

.label {
  padding: 1px 6px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: $dark-8;
  border-radius: 2px;
  position: absolute;
  background: $light;
  z-index: 3;
  visibility: hidden;
  opacity: 0.1;
  transition: 0.2s all ease-in-out;
  transform: translateY(-30px);
  &[data-active="true"] {
    background: $light-3;
    color: $dark-8;
    font-size: 10px;
    transform: translateY(-30px);
    opacity: 1;
    visibility: visible;
    display: block;
  }

  &__white {
    &[data-active="true"] {
      background: $light-3;
      color: $dark-8;
      font-size: 10px;
      transform: translateY(-30px);
      opacity: 1;
      visibility: visible;
      display: block;
      background: $light;
    }
  }
  &[required] {
    padding-right: 12px;
  }

  &[required]::after {
    content: "*";
    position: absolute;
    color: red;
    right: 3px;
    top: 2px;
    font-size: 12px;
    font-weight: 600;
    background: $light;
  }
}
.errorMsg {
  color: $red;
  margin-left: 20px;

  bottom: -18px;
  left: 0;
}
