@import "../../../../styles/variables";
@import "../Table.module";
@import "../../../../styles/base.scss";

.main {
  @extend .base-layout;
  background-color: $light;
  width: 800px;
  border-radius: $defaultBorderRadius;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: auto;
  justify-content: center;
  align-items: stretch;
}

.filessTable {
  width: 100%;
  border-spacing: 0;
}

.tableHeader {
  color: $light;
  background-color: $primary;
  border-right: 1px solid $light;
  border-spacing: 10px;
  font-size: 12px;
  line-height: 24px;
  padding-left: 0.7rem;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: start;
}

.tableData {
  margin: 0;
  padding: 15px;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  color: $dark-2;
  font-size: 12px;
  line-height: 24px;
}

.tableHeader:last-child {
  width: 120px;
  text-align: center;
}

.tableRowClicked {
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  z-index: 3;
  position: relative;
  cursor: pointer;
}

.tableRow {
  cursor: pointer;
}

.arrows {
  width: 48px;
  display: inline-block;
  margin: auto 50px auto 50px;
  height: 1px;
  border: none;
  background-color: #a1a1a1;
  &::before {
    display: block;
    position: relative;
    content: " ";
    border-top: 1.5px solid #a1a1a1;
    border-right: 1.5px solid #a1a1a1;
    width: 10px;
    height: 10px;
    transform: rotate(225deg);
    bottom: 4px;
  }
  &::after {
    display: block;
    position: relative;
    content: " ";
    border-top: 1.5px solid #a1a1a1;
    border-right: 1.5px solid #a1a1a1;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    left: 38px;
    bottom: 14px;
  }
}
