.Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  margin-bottom: 40px;

  & > :first-child {
    margin-right: 5px;
  }
}
