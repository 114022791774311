@import "../../../styles/variables";

.succes {
  padding: 15px 10px;
  border-radius: $defaultBorderRadius;
  background-color: rgb(221, 240, 221);
  display: flex;
  align-items: center;
  img {
    margin-right: 12px;
    margin-left: 10px;
  }
}

.deny {
  @extend .succes;

  background-color: $red-1;
}

.Wrapper {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  &__succes {
    & > :last-child {
      margin: 15px 0 0 10px;
    }
  }

  img {
    width: 15px;
  }
}
