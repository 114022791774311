@import "../../../styles/variables";

.Wrapper {
  display: flex;
  justify-content: center;
  transform: translateX(30px);
  z-index: 2;
  position: relative;
  margin: 0 20px;
}

.element {
  background-color: $light-3;
  min-width: 230px;
  max-width: 315px;
  height: 53px;
  border-radius: $defaultBorderRadius;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 40px 15px 15px;
  position: relative;

  >img{
    position: absolute;
    right: 5px;
  }

  &:first-child {
    width: 100%;
  }

  &::after {
    position: absolute;
    content: "";
    height: 2px;
    width: calc(100% - 2 * $defaultBorderRadius);
    background: #00a094;
    bottom: 0;
    left: $defaultBorderRadius;
    visibility: hidden;
    border-radius: 5px;
    transition: all 0.3s;
    opacity: 0;
  }

  &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: $primary;
    left: 50%;
    bottom: -100px;
  }

  &__noLine {
    &::before {
      content: none;
    }
  }

  &[data-active="true"] {
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
    &::after {
      visibility: visible;
      opacity: 1;
    }
  }
  &__caption{
    display: flex;
    flex-direction: column;
    justify-self: center;
  }
}

.hoverButtons {
  height: 53px;
  display: flex;
  align-items: center;
  margin-left: 14px;
  transition: all 0.3s;
  position: relative;

  &__notEditable {
    & > :last-child {
      order: -1;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  margin-right: 14px;

  button {
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    width: 15px;
    height: 15px;
  }

  &__notEditable {
    button {
      background-color: transparent;
      cursor: default;

      img {
        display: none;
      }
    }
  }
}

.careerPath {
  background-color: $light-3;
  border-radius: $defaultBorderRadius $defaultBorderRadius 0 0;
  align-self: flex-start;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  left: 4px;
  bottom: 51px;
  max-width: 133px;
}

.overWrapper {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 123px;
    bottom: 53px;
    height: 1px;
    width: 30px; /* or 100px */
    border-bottom: 2px solid $primary;
  }
}

.bar {
  content: "";
  position: absolute;
  left: 100px;
  bottom: 53px;
  height: 1px;
  width: 30px; /* or 100px */
  border-bottom: 2px solid $primary;
}
