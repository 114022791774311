@import "../../../styles/variables";

.listItemInfo {
  min-width: 100px;
  max-width: 400px;
  display: table-cell;
  vertical-align: top;
  padding: 15px 0 0 15px;
  width: auto;
  max-height: 135px;
  position: relative;

  &__buttonWrapper {
    display: inline-block;
    margin-left: 10px;
  }

  svg {
    margin-right: 5px;
  }

  button > svg {
    margin: 0;
  }
}
