@import "../../../../styles/variables";
@import "../../../../styles/scroll.scss";

.tableWrapper {
  @extend .scroll;

  display: table;
  border-collapse: collapse;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100%;
}
