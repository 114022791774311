@import "../../../styles/main";

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.nav {
  z-index: 5;
  position: fixed;
  top: 0;
  width: 100%;
  background: $light-1;
  height: 78px;
  box-shadow: 4px 0px 18px 14px rgba(73, 73, 73, 0.08);
  transition: 0.3s;
  &[data-is-scrolling-down="true"] {
    opacity: 0;
    visibility: hidden;
  }
  &[data-transparent="true"] {
    background: rgba(249, 249, 249, 0.6);
  }
}

.layout {
  @extend .base-layout;
  display: flex;
  align-items: center;
  height: inherit;
}

.navLeftSide {
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 35px;
    cursor: pointer;
    width: 128px;
    height: 37px;
  }
}

.navRightSide {
  margin-left: auto;
  display: flex;
  padding-right: 15px;
}

.navRightSide > div:not(:last-child) {
  margin-right: 23px;
}

.iconButton {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.navBarContainer {
  display: flex;
  position: relative;

  & > div:last-child {
    margin-left: 23px;
  }
}

.shadow {
  position: absolute;
  top: 78px;
  background-color: rgba(119, 116, 116, 0.53);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 1;
  transition: 3s opacity ease-in-out;
}
