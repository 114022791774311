@import "../../../styles/main";

.sortPanel {
  display: table-row;
  width: 100%;
  position: relative;

  &[data-isgrid="false"] {
    box-shadow: 0px 1px 0px 0px $light-5;
  }

  &[data-isgrid="true"] {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid $light-5;
  }

  & input[type="checkbox"] {
    display: none;

    &[data-asc="false"] {
      & + label {
        svg {
          transition: 0.3s transform ease-in-out;
          transform: rotate(180deg);
        }

        &:hover {
          svg {
            transform: rotate(0);
          }
        }
      }
    }

    &:checked + label {
      color: $dark-1;
      svg {
        transition: 0.3s transform ease-in-out;
        opacity: 1;
      }

      &:hover::after {
        content: "";
      }
    }

    & + label:hover {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.list {
  width: 100%;
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  cursor: pointer;
  display: table-cell;
  padding: 10px 0 10px 15px;
  vertical-align: top;
}

.box {
  display: table-cell;
}

.label {
  cursor: pointer;
  color: $light-5;
  transition: 0.3s color ease-in-out;
  position: relative;
  display: inline;

  span {
    text-transform: uppercase;
  }

  &:hover {
    color: $dark-1;
  }

  svg {
    margin-left: 6px;
    opacity: 0;
  }

  &:hover > .arrowWrapper {
    &[data-checked="false"] {
      &::after {
        content: url(../../../assets/icons/filterArrow.svg);
        width: 20px;
        height: 20px;
        position: absolute;
        right: -8px;
        bottom: -2px;
      }
    }
  }
}

.arrowWrapper {
  display: inline;
  position: relative;
}
