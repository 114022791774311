@import "../../../../styles/variables";
.noDataInformation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 1px);
  border: 1px solid $light-6;
  border-top: none;
  padding: 10px 0;
}
