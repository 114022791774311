@import "../../../styles/main";

.topBanner {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  height: fit-content;
  min-height: 177px;
  max-height: 309px;

  &::after {
    z-index: 2;
    top: 0;
    display: block;
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: $blueBlur;
  }

  &__bgImage {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
    opacity: 0;
    &[data-isvisible="true"] {
      opacity: 1;
    }
  }

  &__content {
    height: 100%;
    position: relative;
    z-index: 4;
    @extend .base-layout;
    margin-top: 78px;
    display: flex;
    flex-direction: row;
    gap: 30px;
  }

  &__textData {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    flex: 1;
  }

  &__path {
    margin: 26px 0;
    width: 100%;

    span {
      cursor: pointer;
    }

    & > a:last-child > span {
      font-weight: 600;
      color: $light;
    }
  }

  &__bottom {
    display: flex;
    align-items: flex-start;
    margin-bottom: 26px;
  }

  &__image {
    background: #fff;
    border-radius: 3px;
    padding: 9px;
    width: 355px;
    height: 235px;
    position: relative;
    margin-right: 50px;
    bottom: -60px;

    &.square {
      width: 235px;

      img {
        width: 217px;
      }
    }

    &.inactive {
      img {
        filter: grayscale(100%) opacity(75%);
      }
    }

    img {
      margin: auto;
      width: 337px;
      height: 217px;
      object-fit: cover;
      border-radius: 3px;
    }

    &__imgInactiveCover {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  &__left {
    padding-right: 20px;
    position: relative;
    max-width: 1200px;
  }

  &__header {
    margin-bottom: 26px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
  }

  &__informations {
    display: flex;
    width: 100%;
    gap: 45px;
    margin-bottom: 26px;

    &__information {
      display: flex;
      flex-direction: column;
      max-width: 380px;
      padding-right: 45px;

      &:not(:last-child) {
        border-right: 1px solid $light-t;
      }

      & > :first-child {
        margin-bottom: 5px;
      }
    }
  }

  &__description {
    width: 100%;
    max-width: 790px;
    margin-bottom: 26px;
  }

  &__buttons {
    display: flex;
    z-index: 1;

    & > div {
      box-shadow: none !important;
    }

    & > div > div > div {
      padding: 0 !important;

      & > div:first-child {
        font-size: 14px !important;
        background: transparent !important;
        font-weight: normal !important;
        font-family: inherit !important;
        white-space: nowrap;
      }
    }
  }
}


