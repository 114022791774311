@import "../../../styles/variables";
@import "../../../styles/base.scss";
@import "../../../styles/scroll.scss";

.mainWrapper {
  @extend .base-layout;

  & > button {
    margin-top: 20px;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 118px;

  @media (max-width: 1400px) {
    gap: 50px;
  }

  // &__editable {
  //   margin-top: 164px;
  // }
}
.inputsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 36px;
  flex-basis: 50%;
}

.inputWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    margin: 0;
  }

  & > div:first-child {
    flex-basis: 90%;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 0;
  gap: 20px;

  & > button {
    min-width: 100px;
  }
}

.chartContainer {
  @extend .scroll;

  overflow-x: scroll;
  display: flex;
  align-items: center;
  flex-basis: 50%;
  width: 100%;
  background-color: $light;
  border-radius: 9px;
  padding: 20px 0 30px;

  & > div, & > span > div {
    padding: 0px;
    height: auto;
    & > div {
      box-shadow: none;
      min-width: auto;
      border-radius: 0;
      & > div {
        transform: none;

        display: flex;
        // flex-direction: column;
        & > ul > li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-content: center;
          & > li {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }
}

.additionalCharts {
  display: flex;
  flex-direction: column;
  margin-right: 75px;
}

.loaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
