@import "../../../styles/variables";
@import "../../../styles/scroll.scss";

.Wrapper {
  position: absolute;
  left: 0;
  right: 0;
  outline: none;
}

.select {
  width: 100%;
  height: 32px;
  border-radius: $defaultBorderRadius;
  line-height: 32px;
  background-color: $light;
  overflow: hidden;

  &::after {
    content: "";
    background-color: $light;
    height: 25px;
    width: 40px;
    position: absolute;
    top: 2px;
    right: 3px;
  }

  &__white {
    background-color: $light !important;

    &::after {
      background-color: $light;
    }
  }

  &[data-active="true"] {
    background-color: $light;

    &::after {
      background-color: $light;
    }
  }

  &__error {
    border: 1px solid $red;
  }

  &__disabled {
    background-color: $light-3;

    &::after {
      background-color: $light-3;
    }
  }

  &:first-child > span {
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.table {
  @extend .scroll;

  padding: 5px 0;
  position: absolute;
  top: 32px;
  left: -1px;
  width: calc(100% + 2px);
  display: flex;
  flex-direction: column;
  background-color: $light;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  z-index: 4;
  border-bottom: 1px solid $light-3;

  max-height: 200px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.option {
  cursor: pointer;
  margin-bottom: 0;
  margin-left: 20px;

  &__active {
    span {
      color: $primary;
    }
  }
}

.arrow {
  position: absolute;
  z-index: 1;
  top: -2px;
  right: 15px;
  transition: transform 0.2s;

  &[data-active="true"] {
    transform: rotate(180deg);
  }
}

.errorMsg {
  color: $red;
  margin-left: 20px;
  position: absolute;
  bottom: -25px;
  left: -20px;
}

.menuoutertop .Select-menu-outer {
  bottom: 35px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}
