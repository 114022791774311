@import "../../../styles/variables";

.searchInputWrapper {
  position: relative;
  display: flex;
  width: 100%;
  background: $light;
  border-radius: $defaultBorderRadius;
  padding: 5px 12px;
  height: 32px;
  margin-bottom: 33px;
  border: 1px solid #f4f4f4;
  transition: 0.1s box-shadow ease-in-out;

  &:focus-within {
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  }

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.searchInput {
  border: none;
  color: $dark-1;
  outline: none;
  width: 100%;
  padding-left: 25px;
}
