@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import "./mixins";
@import "./variables";
html,
body {
  padding: 0;
  margin: 0;
  background-color: $light-1;
}

body {
  font-family: "Lato", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.base-layout {
  max-width: calc(1920px - 105px);
  margin: 0 auto;

  @media (max-width: 1920px) {
    max-width: calc(100% - 105px);
  }

  @include respond-to("large") {
    max-width: calc(100% - 105px / 2);
  }

  @include respond-to("medium") {
    max-width: calc(100% - 105px / 4);
  }
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
