@import "../../../../styles/variables";

.status {
  & > div.background {
    display: flex;
    align-items: center;
    padding-left: 12px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  & > span {
    position: relative;
  }

  & ul {
    padding-left: 10px;
    margin: 0;
  }
}

.green {
  & > div.background {
    background-color: $green-1;
  }

  & > span {
    color: $green-4;
  }
}

.yellow {
  & > div.background {
    background-color: $yellow-1;
  }

  & > span {
    color: $yellow;
  }
}

.orange {
  & > div.background {
    background-color: $orange-1;
  }

  & > span {
    color: $orange;
  }
}

.red {
  & > div.background {
    background-color: $red-1;
  }

  & > span {
    color: $red;
  }
}

.gray {
  & > div.background {
    background-color: $light-3;
  }

  & > span {
    color: $dark-8;
  }
}
