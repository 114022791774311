.form {
  background: transparent;
  border: 0;
  width: 100%;
  height: 700px;
  margin-top: -46px;
  margin-bottom: -40px;
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
