@import "../../../styles/variables";

.item {
  display: grid;
  grid-template-columns: 35px 190px 60px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: $defaultBorderRadius;
  margin: 0 10px;
  cursor: pointer;

  &:hover {
    background: $light-2;
  }

  &__image {
    max-width: 30px;
    max-height: 30px;
    object-fit: cover;
    align-self: flex-start;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
  }

  &__time {
    display: table-cell;
    text-align: end;
    vertical-align: middle;
  }
}

