@import "../../../styles/variables";

.userPanel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 35px;

  &[data-condensed="true"] {
    margin-top: -41px;

    & > div {
      margin-bottom: 16px;
    }
  }

  &__button {
    margin-bottom: 30px;

    & > * {
      min-width: 137px;
    }
  }
}

.table {
  border-spacing: 0;
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;

  & > tr {
    border-left: 1px solid $light-6;
  }
  tr {
    height: 36px;
  }

  th {
    color: $light;
    background-color: $primary;
    border: 1px solid $light-6;
    font-size: 12px;
    line-height: 24px;
    text-align: start;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
    height: 36px;

    &.displayNone {
      display: none;
    }
  }

  tbody {
    position: relative;
  }

  td {
    margin: 0;
    padding: 5px 10px;
    border: 1px solid $light-6;
    color: $dark-6;
    font-size: 12px;
    line-height: 24px;
    height: 40px;

    & > hr {
      max-width: 35px;
    }
  }

  tfoot {
    font-weight: 800;
  }
}

.tableRowError {
  background-color: $red-1;
  font-weight: 800;

  & > td {
    color: $red !important;
  }
}

.tableRowWarning {
  background-color: $yellow-1;
  font-weight: 600;

  & > td {
    color: $yellow !important;
  }
}

.tableRowClickable {
  cursor: pointer;
}

.tableRowClicked {
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  z-index: 3;
  position: relative;
  cursor: pointer;
}

.tableRowClicked + .tableSubRow > td {
  padding-top: 15px;
}

.tableSubRow {
  background-color: $dark-10;

  & > td:first-child {
    position: relative;
    border-left: none;
    padding-left: 28px;
  }

  &__leftBorder {
    display: block;
    width: 18px;
    height: calc(100% + 1px);
    border-right: 4px solid $primary;
    background-color: $light;
    position: absolute;
    top: 0;
    left: 0;
  }

  & > td:last-child {
    position: relative;
    border-right: none;
    padding-right: 25px;
  }

  &__rightBorder {
    display: block;
    width: 15px;
    height: calc(100% + 1px);
    border-left: 1px solid $light-6;
    background-color: $light;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__borderH100 {
    height: 100%;
  }
}

.pagination {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  flex-direction: row;

  > * {
    margin: 7px;
  }
}

.arrow {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  position: absolute;
  width: 11px;
  height: 11px;
  border-top: 2px solid $dark;
  border-left: 2px solid $dark;
  border-radius: 1px;
  transform: rotate(135deg);
}

.arrowButton {
  position: relative;
  display: flex;
  height: 15px;
  width: 15px;
  margin: 0 6px;
  outline: none;

  button {
    height: 100%;
    background: none;
    border: none;
  }
}

.leftArrow {
  @extend .arrow;
  transform: rotate(-45deg);
}

.rightArrow {
  @extend .arrow;
}

.currPage {
  button {
    background-color: $primary;
    span {
      color: #fff;
    }
  }
}

.pageIndex {
  margin: 0 25px;
}

.select {
  position: relative;

  select {
    cursor: pointer;
    width: 68px;
    padding: 6px 12px;
    outline: none;
    border-radius: $defaultBorderRadius;
    background: $light;
    border: 1px solid $light-6;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
  }

  .selectArrow {
    @extend .arrow;
    transform: rotate(-135deg);
    width: 8px;
    height: 8px;
    right: 15px;
    left: auto;
    margin: auto 0;
    pointer-events: none;
  }
}

.progressBar {
  display: flex;
  padding: 0 8px;
  height: 48px;
  align-items: center;
  border-bottom: 1px solid $light-6;
  border-right: 1px solid $light-6;
}

.imgBadge {
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.sortArrow {
  width: 10px;
  height: 10px;
  border-radius: 1px;
  border-top: 2px solid $light;
  border-left: 2px solid $light;
  background-color: transparent;
  border-bottom: none;
  border-right: none;
  margin-left: 10px;
  padding: 0;
  cursor: pointer;
}

.sortArrowDown {
  @extend .sortArrow;
  transform: translateY(4px) rotate(45deg);
  outline: none;
}

.sortArrowUp {
  @extend .sortArrow;
  transform: translateY(-1px) rotate(225deg);

  outline: none;
}

.actionBox {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.tableHeader {
  // min-width: 140px; TODO: remove because not all tables has 100% of FullHD width
  height: 37px;
  justify-content: center;
  align-items: center;
}

.dropDownHeader {
  text-transform: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
