@import "./variables";

.scroll {
  scrollbar-color: #ebebeb $light;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background: $light;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ebebeb;
    width: 4px;
  }
}
