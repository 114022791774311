@import "../../../styles/variables";

.user {
  background: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  transition: 0.3s box-shadow ease-in-out;
  display: table-row;
  width: 100%;

  & > div {
    display: table-cell;
    vertical-align: top;
    padding: 15px 0 0 15px;

    &:first-child {
      padding: 0;
    }
  }

  &:hover {
    box-shadow: 0px 2px 0px 0px $primary,
      0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  }

  &__innerBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(6, 1fr) 80px;
    margin: 14px 30px;
  }

  &__information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 70px;
  }

  &__informationTeam {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__nameContainer {
    display: flex;
    flex-direction: column;
    max-width: 210px;

    span:first-child {
      padding-bottom: 7px;
    }
  }
}

.imgContainer {
  width: 202px;
  height: 136px;
  min-width: 202px;
  min-height: 136px;
  & > img {
    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  width: 61px;

  button:nth-child(2) {
    margin-left: 15px;
  }
}

.visibilityImg {
  width: 50px;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.hiddenText {
  position: absolute;
  background: $light;
  padding: 16px;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  border-radius: $defaultBorderRadius;
  z-index: 1;
  width: 256px;
  display: flex;
  flex-direction: column;
  top: 90px;
  left: 0;
}
