@import "../../../../styles/variables";

.wrapper {
  background-color: $light;
  border-radius: $defaultBorderRadius;
  padding: 20px 10px 10px 10px;
  &__error {
    border: 1px solid $red;
  }
}

.addButton {
  margin-top: 20px;
  > button {
    width: 137px;
  }
}

.table {
  width: 100%;
  border-spacing: 0px;
  & > thead {
    background-color: $primary;
    color: $light;
    & > tr > td {
      padding: 6px 17px 6px 17px;
    }
  }
  & > tbody > tr > td {
    padding-top: 15px;
    &:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
    }
  }
  & > tbody {
    padding-left: 20px;
  }
}

.arrows {
  width: 48px;
  display: inline-block;
  margin: auto 50px auto 50px;
  height: 1px;
  border: none;
  background-color: #a1a1a1;
  &::before {
    display: block;
    position: relative;
    content: " ";
    border-top: 1.5px solid #a1a1a1;
    border-right: 1.5px solid #a1a1a1;
    width: 10px;
    height: 10px;
    transform: rotate(225deg);
    bottom: 4px;
  }
  &::after {
    display: block;
    position: relative;
    content: " ";
    border-top: 1.5px solid #a1a1a1;
    border-right: 1.5px solid #a1a1a1;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    left: 38px;
    bottom: 14px;
  }
}

.label {
  padding: 1px 6px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: $dark-8;
  border-radius: 2px;
  position: absolute;
  background: $light;
  z-index: 3;
  visibility: hidden;
  opacity: 0.1;
  transition: 0.2s all ease-in-out;
  transform: translateY(-30px);
  &[data-active="true"] {
    background: $light-3;
    color: $dark-8;
    font-size: 10px;
    transform: translateY(-30px);
    opacity: 1;
    visibility: visible;
    display: block;
  }

  &__white {
    &[data-active="true"] {
      background: $light-3;
      color: $dark-8;
      font-size: 10px;
      transform: translateY(-30px);
      opacity: 1;
      visibility: visible;
      display: block;
      background: $light;
    }
  }

  &[required] {
    padding-right: 12px;
  }

  &[required]::after {
    content: "*";
    position: absolute;
    color: red;
    right: 3px;
    top: 2px;
    font-size: 12px;
    font-weight: 600;
    background: $light;
  }
}
.errorMsg {
  color: $red;
  margin-left: 20px;

  bottom: -18px;
  left: 0;
}
