@import "../../../../styles/variables";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  > span {
    white-space: nowrap;
    min-width: 40px;
  }

  > div {
    flex: 1;
  }
}
