@import "../../../styles/variables";

.select {
  min-width: 92px;
  height: 32px;
  line-height: 28px;
  position: relative;
  margin: 0 2px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  color: $dark-4;
  padding-right: 20px;

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: $dark-4;
    position: absolute;
    bottom: 5px;
  }
}

.selectInTable {
  background-color: $light-1;
  width: 142px;
  height: 20px;
  line-height: 32px;
  position: relative;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 6px;
  border-radius: 2px;
  color: $primary;
}

.sortArrow {
  position: absolute;
  right: 5px;
  top: 14px;
  transform: translateY(-50%);
}

.sortArrowInTable {
  @extend .sortArrow;
  > * path {
    stroke: $primary;
  }
}

.table {
  padding: 5px 15px;
  position: absolute;
  top: 35px;
  left: 0;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  background-color: $light;
  box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  border-radius: $defaultBorderRadius;
  z-index: 4;

  div {
    display: block;
    cursor: pointer;

    &:hover {
      span {
        color: $primary;
      }
    }
  }
}
