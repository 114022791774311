@import "../../../../styles/variables";

.tabContent {
  background-color: $dark-7;
  padding: 55px 0;
  position: relative;
}

.padBottom0 {
  padding-bottom: 0;
}
