@import "../../../styles/variables";

.references {
  & > ol > li {
    color: $dark-4;
    line-height: 23px;
    font-size: 14px;
    padding-bottom: 15px;

    &.active {
      font-weight: 800;
    }

    & > a {
      text-decoration: underline;
      color: #14448b;
    }
  }

  &__tags {
    margin-left: 10px;
    padding-left: 10px;
    list-style: none;
    font-weight: normal;
    display: inline-block;

    li {
      margin-right: 20px;
      filter: grayscale(0.6) opacity(0.6);
    }

    li[data-active="true"] {
      filter: drop-shadow(0 0 4px var(--tag-color));
    }

    li[data-clickable="true"] {
      cursor: pointer;
    }

    li, span {
      display: inline-block;
      height: 20px;
      line-height: 18px;
      position: relative;
      font-size: 12px;
    }

    span {
      padding: 0 10px 0 12px;
      background-color: var(--tag-color);
      color: #fff;
      text-decoration: none;
      -moz-border-radius-bottomright: 4px;
      -webkit-border-bottom-right-radius: 4px;
      border-bottom-right-radius: 4px;
      -moz-border-radius-topright: 4px;
      -webkit-border-top-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    span:before {
      content: "";
      float: left;
      position: absolute;
      top: 0;
      left: -11px;
      width: 0;
      height: 0;
      border-color: transparent var(--tag-color) transparent transparent;
      border-style: solid;
      border-width: 11px 11px 9px 0;
    }

    span:after {
      content: "";
      position: absolute;
      top: 9px;
      left: 0;
      float: left;
      width: 4px;
      height: 4px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      background: #fff;
      -moz-box-shadow: -1px -1px 2px #004977;
      -webkit-box-shadow: -1px -1px 2px #004977;
      box-shadow: -1px -1px 2px #004977;
    }

    span:hover {
      //background: #555;
    }

    span:hover:before {
      //border-color: transparent #555 transparent transparent;
    }
  }
}
