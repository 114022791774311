@import "../../../styles/variables";

.fixedButtons {
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  z-index: 99;

  .button {
    position: relative;
    width: 64px;
    height: 64px;
    background: $light;
    padding: 15px;
    border-radius: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    z-index: 10;

    svg {
      fill: $dark-5;
    }
  }
}

.chatbotContainer {
  position: absolute;
  bottom: 80px;
  right: 0;
}
