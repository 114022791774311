@import "../../../styles/main";

.infoPage {
  @extend .base-layout;

  background: #fff;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 40px;
  display: flex;
  flex-direction: row;
  gap: 85px;

  &__leftColumn {
    width: 312px;
    display: flex;
    flex-direction: column;

    &__image {
      width: 312px;
      height: 210px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-bottom: 19px;

      &.inactive {
        img {
          filter: grayscale(100%) opacity(75%);
        }
      }

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__imgInactiveCover {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    &__previewIcon {
      position: absolute;
      top: 50%;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      transform: translate(0, -50%);
      padding: 5px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: $defaultBorderRadius;
        & > span {
          opacity: 1;
        }
      }
      & > span {
        opacity: 0;
      }
    }

    &__header {
      margin-bottom: 9px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      & > span {
        flex: 1;
      }
    }

    &__informations {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__footer {
      display: flex;
      flex-direction: column;
      border-top: 1px $light-7 solid;
      margin-top: 10px;
      padding-top: 10px;

      &__item {
        margin: 10px 0;
        display: flex;
        flex-direction: column;

        &__header {
          margin-bottom: 10px;
        }

        &__content {
          display: flex;
          flex-direction: row;
          gap: 20px;
          align-items: center;

          &__buttons {
            display: flex;
            flex-direction: row;
            gap: 10px;
          }
        }
      }
    }
  }

  &__mainContent {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 1;

    &__footer {
      display: grid;
      grid-template-columns: 1fr;
      margin-top: 30px;
      grid-gap: 20px;

      &__item {
        display: flex;
        flex-direction: column;

        li {
          margin-bottom: 15px;

          & > div {
            color: $dark-2;
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: center;
          }
        }
      }
    }
  }
}
