@import "../../../../styles/variables";

.registrationQuestion {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.fillTheHoles {
  line-height: 32px;
}

.icons {
  fill: black;
  color: black;
}
