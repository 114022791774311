@import "../../../styles/variables";

.workgroup {
  background: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  transition: 0.3s box-shadow ease-in-out;
  display: table-row;
  width: 100%;

  &:hover {
    box-shadow: 0px 2px 0px 0px $primary,
      0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  }

  &__img {
    display: table-cell;
    min-width: 202px;
    min-height: 135px;
    width: 202px;
    height: 135px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__container {
    width: 100%;
    display: table-cell;
    margin: 14px 30px;
  }

  &__information {
    min-width: 100px;
    max-width: 400px;
    display: table-cell;
    vertical-align: top;
    padding: 15px 0 0 15px;
    width: auto;
    max-height: 135px;
  }

  &__informationBtn {
    width: 80px;
    display: table-cell;
    vertical-align: top;
    padding: 15px 33px 0 0;
    width: auto;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;

  button:first-child {
    margin-right: 16px;
  }
}

.visibilityImg {
  margin-top: 15px;
  width: 60px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
