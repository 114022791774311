@import "../../../../styles/variables";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  & > :last-child {
    margin-top: 20px;
  }
}

.img {
  width: 100%;
  height: 140px;
  border-radius: $defaultBorderRadius;
  margin-bottom: 15px;
  object-fit: cover;
}
