@import "../../../styles/variables";

.listItem {
  background: $light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  transition: 0.3s box-shadow ease-in-out;
  display: table-row;
  position: relative;

  &:hover,
  &[data-selected="true"] {
    box-shadow: 0px 2px 0px 0px $primary,
    0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  }

  &.inactive {
    background: $light-1;

    &:hover {
      box-shadow: 0px 2px 0px 0px $dark-5,
      0px 8px 8px 1px rgba(20, 68, 139, 0.15);
    }

    img, svg, button, div, span {
      filter: grayscale(100%) opacity(75%);
    }
  }

  &.selectable {
    cursor: pointer;
  }

  &[data-selected="true"] {
    background: $primary-t;
  }

  &__container {
    width: 100%;
    display: table-cell;
    margin: 14px 30px;
  }

  &__name {
    min-width: 100px;
    max-width: 400px;
    display: table-cell;
    vertical-align: top;
    padding: 15px 0 0 20px;
    width: auto;
    max-height: 135px;
    color: $secondary;

    & > span {
      display: block;
    }
  }

  &__img {
    display: table-cell;
    width: 202px;
    height: 135px;
    padding: 14px;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    &[data-component="true"] {
      vertical-align: middle;

      div {
        margin: 0 auto;
      }
    }

    &[data-imagefit="true"] {
      padding: 0;

      img {
        object-fit: cover;
      }
    }
  }

  &__imgInactiveCover {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__bar {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding-left: 202px;

    & > div {
      border-width: 1px 0 0;
    }
  }

  &__buttons {
    display: table-cell;
    vertical-align: top;
    padding: 15px 33px 0 0;
    width: auto;
    position: relative;

    & > div {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
  }

  &__dot {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 90px;
    right: 33px;
    border-radius: 50%;
    border: 1px solid $dark-4;
    transition: 0.3s all ease-in-out;
    cursor: pointer;

    &Selected {
      background: $primary;
      border: 1px solid $primary;
    }
  }
}

@supports (-webkit-hyphens:none) {
  .listItem {
    transform: translate(0px); // Safari hack to make table row positioned

    &__bar {
      top: 118px;
    }
  }
}