@import "../../../styles/main";

.Wrapper {
  @extend .base-layout;
  position: relative; // TODO: remove when readyToRender flag will be used
  min-height: 350px; // TODO: remove when readyToRender flag will be used
}

.mainContent {
  display: flex;
  width: 100%;
}

.tileWrapper {
  width: 100%;
  padding-left: 10px;
}

.userPanel {
  padding: 0 0 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > :not(:first-child) {
    margin-left: 25px;
  }
}

.loaderWrapper {
  position: relative;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  & > * {
    min-width: 137px;
  }
}
