@import "../../../styles/variables";

.searchItem {
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  &:hover {
    color: $primary;
  }

  & span:first-of-type {
    margin-right: 22px;
    transition: 0.2s color ease-in-out;
  }

  img {
    min-width: 36px;
    max-width: 36px;
    min-height: 36px;
    max-height: 36px;

    border-radius: 50%;
    object-fit: cover;
    margin-right: 14px;
  }
}

.allResults {
  border-top: 1px solid $dark-3;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    cursor: pointer;
    padding: 0 20px;
  }
}
