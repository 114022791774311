@import "../../../styles/variables";

.info {
  display: inline-block;

  & > span {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  &[data-expiring="true"] {
    font-weight: 800;
    color: $red;
  }
}
