@import "../../../styles/variables";

.wrapperRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: calc(100% - 2 * #{$tableHeaderPadding});
  gap: 5px;
  margin: 0 #{$tableHeaderPadding};

  > svg {
    transition: 0.3s transform ease-in-out;
    opacity: 0;
    min-width: 12px;
  }
  path {
    stroke: #f9f9f9;
  }
  &[data-is-sorted="true"] {
    > svg {
      opacity: 1;
      transform: rotate(0);
    }

    &:hover {
      > svg {
        transform: rotate(180deg);
      }
    }
  }

  &[data-is-sorted-desc="true"] {
    > svg {
      transform: rotate(180deg);
    }

    &:hover {
      > svg {
        transform: rotate(0);
      }
    }
  }

  &:hover[data-is-sortable="true"] {
    > svg {
      opacity: 1;
    }
  }

  &[data-full-width="true"] {
    & > .innerWrapper {
      width: 100%;
    }
  }
}

.markerColor {
  min-width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: var(--header-marker-color);
}
