@import "../../../../styles/variables";

.Wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 92px;
  flex-grow: 1;
  cursor: pointer;
  padding-left: 15px;

  & > :last-child {
    margin-right: 20px;
  }

  &__active {
    background-color: $chat-selected !important;
  }
}

.bigProfilePhoto {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-right: 11px;
}

.smallPhoto {
  height: 18px;
  width: 18px;
  border-radius: 50%;
}

.infoArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
}

.icon {
  min-width: 18px;
  height: 18px;
}
