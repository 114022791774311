@import "../../../styles/variables";

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 316px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 10px 30px 30px 20px;
  background-color: $light;
  position: relative;
}

.content {
  padding-top: 22px;
  display: flex;
  justify-content: space-between;
}

.contentW100 {
  @extend .content;
  justify-content: space-between;
  margin-right: 120px;
}

.legend {
  margin-top: 15px;
}
