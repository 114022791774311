@import "../../../styles/variables";

.radioInputsWrapper {
  position: relative;
  align-items: center;
  display: grid;
  grid-template-columns: 35px 1fr 350px;
  grid-row-gap: 20px;
  row-gap: 20px;
  background-color: #fff;
  padding: 16px 10px 16px 20px;
  border-radius: $defaultBorderRadius;
}

.radioInput {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 25px;
}
