@import "../../../styles/variables";

.Wrapper {
  position: relative;
  width: 100%;
  display: flex;
  height: 32px;
  margin: 0;
  margin-bottom: 36px;

  &__textArea {
    height: 95px;
  }

  &__noMargin {
    margin: 0;
  }

  &__absolute {
    position: absolute;
    margin-bottom: 0;
    height: 0;
    top: 0;
  }

  &__searchBar {
    position: relative;
    &::before {
      content: url(../../../assets/icons/loupe.svg);
      position: absolute;
      right: 17px;
      top: 7px;
      z-index: 2;
    }
  }
}

.placeholderIn {
  padding: 1px 6px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: $dark-8;
  border-radius: 2px;
  position: absolute;
  display: flex;
  top: 9px;
  left: 20px;
  z-index: 3;
  pointer-events: none;
  opacity: 1;

  &__animation {
    transition: all 0s ease 0.2s;
  }

  &[data-required="true"]::after {
    content: "*";
    position: absolute;
    background-color: transparent;
    right: -3px;
    top: 3px;
    font-size: 12px;
    font-weight: 600;
    color: $red;
  }

  &__onFocus {
    opacity: 0;
  }
}

.placeholderOut {
  padding: 1px 6px;
  font-size: 12px;
  line-height: 12px;
  height: 12px;
  font-weight: 600;
  color: $dark-2;
  border-radius: 2px;
  position: absolute;
  top: 3px;
  background-color: $light !important;
  left: 20px;
  z-index: 3;
  visibility: hidden;
  opacity: 0.1;
  transition: 0.2s all ease-in-out;

  &__animation {
    transition: 0.2s all ease-in-out 0.17s;
  }

  &[data-required="true"]::after {
    content: "*";
    position: absolute;
    top: -1px;
    margin-left: 4px;
    font-size: 12px;
    margin-top: 5px;
    color: $red;
    font-weight: 600;
    background: transparent;
  }

  &__onFocus {
    background: $light;
    color: $dark-8;
    font-size: 10px;
    transform: translateY(-13px);
    opacity: 1;
    visibility: visible;
    display: block;
    background: transparent;
  }

  &__disabled {
    background-color: $light-3 !important;
  }

  &__transparent {
    background-color: transparent !important;
  }

  &[data-required="true"] {
    padding-right: 13px;
  }
}

.errorMsg {
  color: $red;
  margin-left: 20px;
  position: absolute;
  bottom: -18px;
  left: 0;
}
