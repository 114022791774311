@import "../../../styles/main";

.container {
  display: flex;
  align-items: center;

  button {
    cursor: pointer;
    outline: none;
    padding: 0;
    border: none;
  }
}

.linearButton {
  height: 14px;
  width: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 30px;

  div {
    background: #777;
    transition: all 0.2s;
  }

  &[data-isactive="true"] {
    div {
      background: $primary;
    }
  }
}

.gridButton {
  height: 18px;
  width: 18px;

  div > div {
    background: #777;
    transition: all 0.2s;
  }

  &[data-isactive="true"] {
    div > div {
      background: $primary;
    }
  }
}

.row {
  display: flex;
  &:first-child {
    margin-bottom: 3px;
  }
  div:first-child {
    margin-right: 3px;
  }
}

.square {
  height: 7px;
  width: 7px;
  background: #777;
  border-radius: 2px;
}

.line {
  width: 100%;
  height: 2px;
  border-radius: 5px;
}
