@import "../../../styles/variables";

.rating {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
    height: 32px;
    padding-left: 10px;
  }

  &__starIcon {
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
}


