@import "../../../../styles/variables";

.RegistrationPairUp {
  padding-top: 40px;
  > span:first-child {
    display: block;
    padding-bottom: 24px;
  }
}

.pairRow {
  display: flex;
  align-items: center;
  padding-top: 11px;

  svg {
    margin: 0 50px;
  }
}

.pairAnswer {
  border-radius: $defaultBorderRadius;
  border: 1px solid $light-7;
  background-color: $light-10;
  width: 300px;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  user-select: none;

  &[data-dragging="true"] {
    border-bottom: 4px solid rgba(20, 68, 139, 0.15);
  }
}
