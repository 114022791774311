@import "../../../../styles/variables";
@import "../../../../styles/scroll.scss";

.Wrapper {
  @extend .scroll;

  display: flex;
  flex-direction: column;
  margin-bottom: 0px !important;
}

.textWrapper {
  text-align: center;
  margin-bottom: 20px;
}

.Buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  > * {
    width: 84px;
    margin-left: 20px;
  }
}

.checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../../../../assets/icons/checkbox.svg");
  cursor: pointer;
  margin-right: 17px;
  padding-right: 18px;
}

.wrapper {
  display: flex;
  align-items: center;

  & input[type="checkbox"] {
    display: none;
  }

  & input[type="checkbox"]:checked + .checkbox {
    background-image: url("../../../../assets/icons/checkbox-checked.svg");
  }

  & input[type="checkbox"]:checked ~ .label {
    color: $primary;
  }
}
