@import "../../../../styles/variables";

.inputWrapper {
  cursor: pointer;
  background-color: #fff;
  display: inline-block;
  width: 85px;
  height: 24px;
  position: relative;
  border: 1px solid $primary;
  border-radius: $defaultBorderRadius;

  input {
    z-index: 2;
    background: transparent;
    padding: 0;
    position: absolute;
    bottom: 4px;
    left: 7px;
    font-size: 10px;
    width: 90%;
    color: $dark-4;
    border: none;

    &:focus {
      outline: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .placeholder {
    z-index: 1;
    background-color: #fff;
    color: #000;
    position: absolute;
    left: 4px;
    top: -9px;
    pointer-events: none;
    font-size: 10px;
    transition: all 0.3s;
    padding: 1px 3px;
  }

  .enlarged {
    top: 8px;
    left: 3px;
    transition: all 0.3s;
    border: 1px solid red;
  }
}
