// colors
$primary: #00a094;
$primary-t: rgba(0, 160, 148, 0.08);
$secondary: #14448b;

// calendar
$range: #d1e7e5;
$ends: #00a094;

//PieChartTotal colors
$piechart-1: #b4e7e3;
$piechart-2: #6cebe1;
$piechart-3: #4b8a85;

// light pallete
$light: #fff;
$light-t: rgba(255, 255, 255, 0.7);
$light-1: #f9f9f9;
$light-2: #f4f4f4;
$light-3: #eaeaea;
$light-4: #f2f2f2;
$light-5: #a1a1a1;
$light-6: #eeeeee;
$light-7: #b9b4b4;
$light-8: #898989;
$light-9: #e5e5e5;
$light-10: #f6f6f6;

// dark pallete
$dark: #000;
$dark-1: #333333;
$dark-2: #777777;
$dark-3: #c5c5c5;
$dark-4: #414141;
$dark-5: #c2c2c2;
$dark-6: #616161;
$dark-7: #f3f3f3;
$dark-8: #9e9e9e;
$dark-9: #383838;
$dark-10: #f8f8f8;

// green
$green: #08b60f;
$green-1: #bcf3be;
$green-4: #257824;

// red
$red: #ca3131;
$red-1: #ffa9a9;

// yellow
$yellow: #deb522;
$yellow-1: #ffffcc;

// orange
$orange: #f27f2a;
$orange-1: #ffd9b3;

//Purple
$purple: #7e39b4;

// topImageBackgroundBlur
$blueBlur: rgba(0, 21, 68, 0.72);

// layout constants
$defaultBorderRadius: 7px;
$tableHeaderPadding: 9px;

$background-color: #f9f9f9;

$navbar-height: 78px;

$box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
//breakpoints

//popup
$popupBackground: rgba(119, 116, 116, 0.53);

//scrollBars
$scrollChromeWidth: 4px;
$scrollChromeBg: #ebebeb;
$scrollFirefoxColor: #ebebeb $light;
$scrollFirefoxWidth: thin;

//badges
$lvl-0: #e6e6e6;
$lvl-1: #08b60f;
$lvl-2: #257824;
$lvl-3: #4cccd5;
$lvl-4: #393eb4;
$lvl-5: #af3df4;
$lvl-6: #ce4cc1;
$lvl-7: #cc5d64;
$lvl-8: #ca3131;
$lvl-9: #f27f2a;
$lvl-10: #deb522;

//chat
$chat-border: #e2e2e2;
$chat-selected: #d7e6e4;

//click bars
$greenBar: rgba(0, 160, 148, 0.32);
$searchBarFocus: #eef3f8;

//

$breakpoints: (
  "small": (
    max-width: 400px,
  ),
  "medium": (
    max-width: 800px,
  ),
  "mid-large": (
    max-width: 1000px,
  ),
  "large": (
    max-width: 1300px,
  ),
  "default": (
    max-width: 1440px,
  ),
  "huge": (
    min-width: 1921px,
  ),
);
