@import "../../../styles/variables";
@import "../../atoms/Label/Label.module.scss";

.input {
  background: $light;
  border: none;
  width: 100%;
  border-radius: $defaultBorderRadius;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: $dark-4;
  height: 32px;
  padding-left: 20px;
  outline: none;
  transition: 0.2s background ease-in-out;

  &[data-dark-border="true"] {
    border: 1px solid $light-3;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: 0s;
    box-shadow: 0 0 0 30px $light inset !important;
  }

  &:-webkit-autofill + span,
  &:-webkit-autofill:hover + span,
  &:-webkit-autofill:focus + span,
  &:-webkit-autofill:active + span {
    transition: 0s;
    box-shadow: 0 0 0 30px $light inset !important;
    opacity: 0;
  }

  &:-webkit-autofill + span + span,
  &:-webkit-autofill:hover + span + span,
  &:-webkit-autofill:focus + span + span,
  &:-webkit-autofill:active + span + span {
    transition: 0s;
    background: $light;
    color: $dark-8;
    font-size: 10px;
    transform: translateY(-13px);
    opacity: 1;
    visibility: visible !important;
    display: block;
    background: transparent;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }

  &__disabled {
    background-color: $light-3;
  }

  &__error {
    border: 1px solid $red;
  }
}
