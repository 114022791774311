@import "../../../styles/variables";

.timeBar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 1px 8px;
  background: $light;
  border: 1px solid $light-9;
  overflow: hidden;
  width: 100%;

  span {
    z-index: 2;
  }

  &__background {
    position: absolute;
    height: 100%;
    border-radius: 0 5px 5px 0;
    left: 0;
    top: 0;
    background: $primary;
    transition: 0.3s width ease-in-out;

    &[data-progress="1"] {
      border-radius: 0;
    }
  }
}
