@import "../Table.module";

.table {
  min-width: 100%;
}

.wrapper {
  background-color: $light;
  padding: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
}

.greenRow {
  background-color: $primary;
  cursor: default;
  td {
    color: $light !important;
    font-size: 12px;
  }
  th:nth-child(6) {
    width: 180px;
  }
}

.tableHeader {
  padding: 10px;
  font-size: 12px;
  font-weight: normal;
  cursor: default !important;
}
