@import "../../../styles/variables";

.inputWrapper {
  position: relative;
  width: 100%;
}

.arrowWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  right: 15px;
  top: 6px;
  & > img {
    width: 10px;
  }
}
