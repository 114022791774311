@import "../../../styles/variables";

.label {
  cursor: pointer;
  color: $dark-4;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;

  &__disabled {
    span {
      color: $dark-8;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
}
