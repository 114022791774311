//scale :
.opacityEnter {
  opacity: 0;
  transition: opacity 0.3s;
}

.opacityEnterActive {
  opacity: 1;
  transition: opacity 0.3s;
}

.opacityExit {
  opacity: 1;
  transition: opacity 0.3s;
}

.opacityExitActive {
  opacity: 0;
  transition: opacity 0.3s;
}

//scale

.scaleEnter {
  transform: scale(0);
  transition: transform 0.3s;
}

.scaleEnterActive {
  transform: scale(1);
  transition: transform 0.3s;
}

.scaleExit {
  transform: scale(1);
  transition: transform 0.3s;
}

.scaleExitActive {
  transform: scale(0);
  transition: transform 0.3s;
}

//trasform

.translateEnter {
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 1;
}

.translateEnterActive {
  transform: translateY(0);
  transition: transform 0.3s;
  z-index: 1;
}

.translateExit {
  transform: translateY(0);
  transition: transform 0.3s;
  z-index: 1;
}

.translateExitActive {
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 1;
}


