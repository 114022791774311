@import "../../../styles/variables";

.roundImage {
  width: var(--image-size);
  height: var(--image-size);
  min-width: var(--image-size);

  svg {
    width: var(--image-size);
    height: var(--image-size);
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
