@import "../../../styles/variables";

.courseAssign {
  display: flex;
  align-items: center;

  > :nth-child(2) {
    padding: 0 30px 0 15px;
  }
}
