.Wrapper {
  display: flex;
  position: relative;

  &__fromOne {
    ul {
      padding-top: 100px;
    }
  }
}

.treeNode {
  z-index: 0;
  display: block;

  &::before {
    transform: translateY(-74px);
    height: 125px !important;
    z-index: 0 !important;
  }
  &::after {
    transform: translateY(-74px);
    height: 125px !important;
    z-index: 0 !important;
  }

  &__fromOne {
    &:last-child {
      padding-top: 50px !important;
    }
  }
}