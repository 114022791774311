.popupItem {
  display: flex;
  gap: 5px;
  flex-direction: column;
  margin: 10px 0;

  &__row {
    display: flex;
    gap: 3px;
    align-items: center;
  }
}
