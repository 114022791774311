@import "../../../../styles/variables";

.Wrapper {
  height: 40px;
  width: 100%;
  padding: 0 13px 0 17px;
  background-color: $red-1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    margin-left: 14px;
  }

  button {
    cursor: pointer;
    margin-left: 20px;
  }
}
