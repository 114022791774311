@import "../../../../styles/base.scss";
@import "../../../../styles/variables";

.Wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.competitions {
  @extend .base-layout;
  width: 100%;
}
