@import "../../../styles/variables";

.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    color: $dark-2;
    background: $light-t;
    padding: 10px 20px;
    border-radius: 20px;
    opacity: 0.0;
    transition: all 0.3s;
    cursor: default;
  }

  &[data-clickable="true"] {
    cursor: pointer;

    & > span {
      cursor: pointer;
      opacity: 1.0;
    }
  }
}

.playImg {
  height: 76px;
  width: 76px !important;
  position: absolute;
  top: calc(50% - 37px);
  left: calc(50% - 37px);
}
