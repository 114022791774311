@import "../../../styles/variables";

.statistic {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background: $light;
  border-radius: 9px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  transition: 0.3s box-shadow ease-in-out;
  gap: 40px;
  padding: 40px;

  @media (max-width: 1400px) {
    gap: 20px;
    padding: 20px;
  }

  &:hover {
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  }
  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: calc(100% - 10px);
    height: 2px;
    background: $primary;
    z-index: 1;
    border-radius: 3px;
    transition: 0.3s opacity ease-in-out;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  &__icon {
    min-width: 177px;
    min-height: 177px;
    border: 4px solid $dark-3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s border ease-in-out;
  }

  &:hover .statistic__icon {
    border: 4px solid $primary;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;

    & > span:first-child {
      margin-bottom: 20px;
    }
  }

  &__values {
    display: flex;
    gap: 20px;

    &__trend {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
}
