@import "../../../../styles/variables";

.registrationQuestion {
  padding-top: 40px;

  > span:first-child {
    display: block;
    padding-bottom: 24px;
  }

  > * {
    padding-bottom: 12px;
  }
}

.checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../../../../assets/icons/checkbox.svg");
  cursor: pointer;
  margin-right: 17px;
}

.label {
  cursor: pointer;
  color: $dark-4;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex: 1;
}

.wrapper {
  display: flex;

  & input[type="checkbox"] {
    display: none;
  }

  & input[type="checkbox"]:checked + .checkbox {
    background-image: url("../../../../assets/icons/checkbox-checked.svg");
  }

  & input[type="checkbox"]:checked ~ .label {
    color: $primary;
  }
}

.innerWrapper {
  display: flex;
  align-items: center;
}
