@import "../../../styles/variables";
@import "../../../styles/scroll.scss";

.navBarInput {
  border: none;
  color: $dark-1;
  outline: none;
  width: 90%;
  background-color: inherit;

  &:focus {
    background-color: $searchBarFocus;
  }
}

.searchIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBarInputWrapper {
  display: flex;
  min-width: 400px;
  width: 100%;
  background: $light;
  border-radius: $defaultBorderRadius;
  padding: 5px 12px;
  height: 32px;

  &:focus-within {
    background-color: $searchBarFocus;
  }

  img {
    margin-right: 8px;
  }
}

.inputHints {
  position: absolute;
  width: 100%;
  max-height: 90vh;
  background: $light;
  border-radius: $defaultBorderRadius;
  top: 15px;
  left: 0;
  padding: 0 0 11px 0;
  list-style: none;
  opacity: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  @extend .scroll;
  &[data-active="true"] {
    opacity: 1;
  }
  z-index: 2;
}

.hintItem {
  cursor: pointer;
  padding: 6px 12px;
  display: flex;
  align-items: center;

  &:hover {
    color: $primary;
    background-color: $light-10;
  }

  & span:first-of-type {
    margin-right: 22px;
    transition: 0.2s color ease-in-out;
  }

  img {
    min-width: 36px;
    min-height: 36px;
    max-height: 36px;
    max-width: 36px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 14px;
  }
}

.allResults {
  border-top: 1px solid $light-4;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    cursor: pointer;
    padding: 0 20px;
  }
}

.hintWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 0;
}
