@import "../../../styles/base.scss";
@import "../../../styles/variables";

.courseDetails {
  &__box {
    @extend .base-layout;

    background: #fff;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
}

.statWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: $dark-7;
  gap: 50px;

  @media (max-width: 1400px) {
    gap: 25px;
  }

  @extend .base-layout;
}

.description {
  @media (max-width: 1450px) {
    margin-top: 50px;
  }

  line-height: 24px;
  h1 {
    color: $secondary;
    font-weight: 800;
    font-size: 24px;
  }
  h2 {
    margin-top: 70px;
    color: $secondary;
    font-weight: 800;
    font-size: 20px;
  }
  p,
  ul {
    color: $dark-6;
    font-size: 12px;
    font-weight: 400;
  }
}
