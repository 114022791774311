@import "../../../styles/variables";

.tileItem {
  position: relative;
  background: $light;
  width: 312px;
  padding: 16px 16px 50px;
  margin-right: 0px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  transition: 0.3s box-shadow ease-in-out;

  &:hover,
  &[data-selected="true"] {
    box-shadow: 0px 8px 8px 1px rgba(20, 68, 139, 0.15);
  }

  &.inactive {
    background: $light-1;

    &::before {
      background: $dark-5;
    }

    img, svg, button, div, span {
      filter: grayscale(100%) opacity(75%);
    }
  }

  &.selectable {
    cursor: pointer;
  }

  &[data-selected="true"] {
    background: $primary-t;
  }

  &.noMargin {
    padding-bottom: 16px;
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: calc(100% - 5px);
    height: 2px;
    background: $primary;
    z-index: 1;
    border-radius: 3px;
    transition: 0.3s opacity ease-in-out;
    opacity: 0;
  }

  &:hover::before,
  &[data-selected="true"]::before {
    opacity: 1;
  }

  &__header {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    margin-bottom: 15px;
  }

  &__name {
    display: flex;
    flex-direction: column;
    min-height: 93px;
    max-width: 160px;

    & > div > span > span:first-child > span {
      margin-right: 4px;
      display: inline-block;
      hyphens: auto;
    }
  }

  &__img {
    width: 116px;
    height: 93px;
    position: absolute;
    top: 16px;
    right: 16px;

    img {
      width: 100%;
      height: 100%;
    }

    &[data-imagefit="true"] img {
      object-fit: cover;
    }
  }

  &__imgInactiveCover {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__informations {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__bar {
    grid-column: 1/3;
    margin: 10px 0;
  }

  &__buttons {
    display: flex;
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);

    button {
      margin-right: 10px;
    }
  }

  &__dot {
    width: 18px;
    height: 18px;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    border: 1px solid #414141;
    transition: 0.3s all ease-in-out;
    cursor: pointer;

    &Selected {
      background: $primary;
      border: 1px solid $primary;
    }
  }
}

